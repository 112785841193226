import { Button, Dialog, DialogBody, DialogFooter, Switch ,Label, H4} from "@blueprintjs/core"
import React, { useEffect, useState }   from 'react';
import { BusinessAndSiteDropdown } from "./BusinessAndSiteDropdown";
import { collection, doc, getDocs, setDoc } from "firebase/firestore";
import { firebase, UsersCollection } from "../global/firebase";
import { useAuthUser, useSignIn} from 'react-auth-kit';
import Select from 'react-select';
export const EditUserPopup=(data:any)=>{
    console.log(data.user);
    
    const [userrole ,setUserrole]=useState(data.user.accountData.permissions.role); 
    const [open ,setopen]=useState(true);  
    const [isDashboard,setIsDashboard]=useState(data.user.accountData.permissions.isDashboard as boolean)
    const [ismask,setIsmask]=useState(data.user.accountData.permissions.ismask as boolean)
    const [isVoidCheckIn,setIsVoidCheckIn]=useState(data.user.accountData.permissions.isVoidCheckIn as boolean)
    const [HideCheckinHistory,setHideCheckinHistory]=useState(data.user.accountData.permissions.HideCheckinHistory as boolean)
    const [HideTodayscheckin,setHideTodayscheckin]=useState(data.user.accountData.permissions.HideTodayscheckin as boolean)
    const [HideCheckoutpage,setHideCheckoutpage]=useState(data.user.accountData.permissions.HideCheckoutpage as boolean)
    const [archivecheckinreport,setarchivecheckinreport]=useState(data.user.accountData.permissions.archivecheckinreport as boolean)
    const [checkinsummaryreport,setcheckinsummaryreport]=useState(data.user.accountData.permissions.checkinsummaryreport as boolean)
    const [pointsystemquery,setpointsystemquery]=useState(data.user.accountData.permissions.pointsystemquery as boolean)
    const [groups,setgroups]=useState([]);
    const [selectedOptions,setselectedOptions]=useState(data.user.accountData.permissions.groups as object);
    const signIn = useSignIn()
    const auth:any = useAuthUser();

        useEffect(()=>{
if(groups.length<=0){
                 getDocs(collection(firebase, "BusinessGroups")).then((docs)=>{
              
               if(!docs.empty){   
                const arr :any=[]
                docs.docs.map((val:any)=>{
                    arr.push( { value: val.id, label: val.data().name} )   
                  
                })
                setgroups(arr);
               }

            })
        }

        })
    

const updatedUser=async(r:any)=>{
    const  user:any = r;

    user.accountData.permissions.role = userrole
    user.accountData.permissions.groups = selectedOptions==undefined ? [] : selectedOptions
    user.accountData.permissions.isDashboard = isDashboard===undefined || isDashboard===null|| isDashboard===false && userrole!=='admin' ? false : true  
    user.accountData.permissions.isVoidCheckIn = isVoidCheckIn===undefined || isVoidCheckIn===null|| isVoidCheckIn===false ? false : true     
    user.accountData.permissions.ismask =ismask===undefined || ismask===null|| ismask===false ? false : true  

    user.accountData.permissions.HideCheckinHistory =HideCheckinHistory===undefined || HideCheckinHistory===null|| HideCheckinHistory===false ? false : true 
    user.accountData.permissions.HideTodayscheckin =HideTodayscheckin===undefined || HideTodayscheckin===null|| HideTodayscheckin===false ? false : true 
    user.accountData.permissions.HideCheckoutpage =HideCheckoutpage===undefined || HideCheckoutpage===null|| HideCheckoutpage===false ? false : true 
    
    user.accountData.permissions.checkinsummaryreport =checkinsummaryreport===undefined || checkinsummaryreport===null|| checkinsummaryreport===false ? false : true 

    user.accountData.permissions.archivecheckinreport =archivecheckinreport===undefined || archivecheckinreport===null|| archivecheckinreport===false ? false : true 
    user.accountData.permissions.pointsystemquery =pointsystemquery===undefined || pointsystemquery===null|| pointsystemquery===false ? false : true 

//    if(auth().accountData.email=== user.accountData.email){
//     try {

//     signIn(
//         {
//             token: user.uid,
//             expiresIn:24894848584,
//             tokenType: "Bearer",
//             authState: user,
//             // refreshToken: '0',                    // Only if you are using refreshToken feature
//             // refreshTokenExpireIn:      // Only if you are using refreshToken feature
//         }
//     )
            
// } catch (error) {
//        console.log(error);
        
// }
// }
console.log(user,'koo');

    await setDoc(doc(UsersCollection, user.userId), 
    user
        ).catch((e)=>{
console.log(e,'update doc error');

        });
        setopen(false)
}

const handleHideCheckinHistory=(e:any)=>{
      setHideCheckinHistory(e.target.checked)
      
    }
 const handleHideTodayscheckin=(e:any)=>{
      setHideTodayscheckin(e.target.checked)
      
    }
 const handleHideCheckoutpage=(e:any)=>{    
      setHideCheckoutpage(e.target.checked)
      
    }
    const handleChange = (selected:any) => {
        setselectedOptions(selected);
      };
    return(
        <Dialog title="Edit User" icon="info-sign" isOpen={open} onClose={data.Onclose()}>
    <DialogBody>
    <select defaultValue={userrole} className="roleDropdown" onClick={(e:any)=>setUserrole(e.target.value)}>
        <option value="admin">admin</option>
        <option value="owner"  >owner</option>
        <option value="staff"  >staff</option>
        <option value="user" >user</option>
    </select>

    <Label className="mt-10"><Switch  labelElement={"Sensitive Data Mask"} checked={ismask} onChange={(e:any)=>setIsmask(e.target.checked)} innerLabelChecked="on" innerLabel="off" />
    <Switch  labelElement={"Enable Dashboard Page"} innerLabelChecked="on"  checked={userrole==='admin' ? true : isDashboard}  onChange={(e:any)=>setIsDashboard(e.target.checked)}  innerLabel="off" />
    <Switch  labelElement={"Enable Void check-in"} innerLabelChecked="on"  checked={isVoidCheckIn}  onChange={(e:any)=>setIsVoidCheckIn(e.target.checked)}  innerLabel="off" />
    <Switch  labelElement={"Enable Check-Ins Report older than 12 months"} innerLabelChecked="on"  checked={archivecheckinreport}  onChange={(e:any)=>setarchivecheckinreport(e.target.checked)}  innerLabel="off" />
    <Switch  labelElement={"Enable Check-Ins Summary Report"} innerLabelChecked="on"  checked={checkinsummaryreport}  onChange={(e:any)=>setcheckinsummaryreport(e.target.checked)}  innerLabel="off" />
    <Switch  labelElement={"Enable Point System Query Option"} innerLabelChecked="on"  checked={pointsystemquery}  onChange={(e:any)=>setpointsystemquery(e.target.checked)}  innerLabel="off" /></Label>
    
    {userrole === 'staff'? <div>
            <Switch label="Hide Check-In History" checked={ HideCheckinHistory } onChange={handleHideCheckinHistory} />
            <Switch label="Hide Checkout Page" checked={ HideCheckoutpage } onChange={handleHideCheckoutpage} />
            <Switch label="Hide Todays-Check-Ins" checked={ HideTodayscheckin } onChange={handleHideTodayscheckin} />
            </div> :'' }

            <H4>Business Group permissions</H4>

            <Select                  
                    options={groups}
                    isMulti
                    value={selectedOptions}
                    onChange={handleChange}
                    placeholder="Select Group"                 
            />
{/* 
     <select multiple  className="busiteDropdown" onClick={(e:any)=>setUserrole(e.target.value)}>
        <option value={''}>Select Group</option>
     {groups.map((v:any)=>(   <option value={v.name}>{v.name}</option>))}

    </select> */}
<br/>
<H4>Business permissions</H4>
    <BusinessAndSiteDropdown user={data.user} returnAfterUpdate={(r:any)=>updatedUser(r)}/>
    </DialogBody>
</Dialog>
    )
}