
import { Button, ButtonGroup, Dialog, DialogBody, H1, H2, Icon, Spinner } from "@blueprintjs/core"
import React, { useEffect, useState } from "react"
import { strings } from "./strings"
import { collection, deleteDoc, doc, getDoc, getDocs, query, updateDoc, where } from "firebase/firestore"
import { firebase } from "../../global/firebase"
import { MakeModelYearInput } from "./makeModelYearInput"
import { textAlign } from "html2canvas/dist/types/css/property-descriptors/text-align"

import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
export const VehiclePicksPage=(data:any)=>{
    const [lang,setlang]=useState(strings.eng)
    const [langstr,setlangstr]=useState('eng')
    const [collectionName,setcollectionName]=useState('')
    
    const [picksShow,setpicksShow]=useState(true)
    const [newpicksShow,setnewpicksShow]=useState(true)
    const [picks,setpicks]=useState([] as any)
    const [newVehicles,setnewVehicles]=useState([] as any);
    const [info,setinfo]=useState({} as any)
    const [infomodal123,setinfomodal123]=useState(false)
    const [Helpmodal,setHelpmodal]=useState(false)
    const [addnewpickmodal,setaddnewpickmodal]=useState(false)
    const [site,setsite]=useState([] as any);
    const [mobile,setMobilex]=useState('')
    useEffect(()=>{

if(picks[0]==undefined){
    getPicks();
}

        if(window.innerWidth<767 || data.logo==null){
            setnewpicksShow(false)
        }else{
            setnewpicksShow(true)
        }

        window.addEventListener('resize', ()=>{
        if(window.innerWidth<767 || data.logo==null){
            setnewpicksShow(false)
        }else{
            setnewpicksShow(true)
        }
    })
    },[data])

    
const getPicks=async()=>{
    let collectionName = data.feedType=='s3id' ? 'crushS3Feed':'hollanderUrg';
    let date = new Date()
    date.setDate(date.getDate() - 7);
console.log(data,'okay');

if(data.sitePath!=='-' && data.siteId!==null && data.siteId!=='' && data.mobile!==null && data.mobile!==undefined && data.mobile!==''){
const docRef = doc(firebase, "businesses/"+data.businessId+"/sites", data.siteId);
const docSnap = await getDoc(docRef);

if (docSnap.exists()) {
  console.log("Document data:", docSnap.data());
  setsite( docSnap.data())
  try {
    if(
        'yardsmartfeed' in docSnap.data() && docSnap.data().yardsmartfeed==true &&
        'yardsmartid' in docSnap.data() && docSnap.data().yardsmartid!==null && docSnap.data().yardsmartid!==''){
      collectionName ='yardsmartfeed'
      }    
      
  } catch (error) {
    console.log(error);
    
  }

} else {
  console.log("No such document! site");
}
   setcollectionName(collectionName);
  const que = await  getDocs(query(collection(firebase,'vehiclePicks'),where('businessId','==',data.businessId),where('siteId','==',data.siteId),where('mobile','==',data.mobile)))
   
  if(!que.empty){
       const arr:any = [];       
       que.docs.forEach(async(vehicle:any)=>{  
         const  vehi = vehicle.data()
              
          console.log(collectionName);
                   
          const que = await  getDocs(query(collection(firebase,collectionName),
          where('businessId','==',vehi.businessId),
          where('siteId','==',vehi.siteId),
          where('freshOn','>=',date),
          where('make','==',vehi.make),
        //   where('model','in',vehi.modelsGroup)
          ))    
           const arrx:any = [];             
             if(!que.empty){          
               que.docs.forEach((v:any)=>{     
                try {        
                  if(v.data()['year']<=vehi['yearMax'] && v.data()['year']>=vehi['yearMin']){  
                    const modell =(v.data()['model']).toUpperCase()
                                       
                    if(vehi.modelsGroup!==undefined && vehi.modelsGroup.includes(modell)){                       
                        
                        arrx.push(v.data())
                    }else if(vehi.model===v.data()['model']){
                              arrx.push(v.data())
                          }         
                   
                    }
                    
                    
                } catch (error) {
                  console.log(error);
                    
                }   
              })                        
            }
        vehicle.newVehicles=arrx      
        
        arr.push(vehicle)
      })   
    setTimeout(() => {
      setpicks(arr)           
    }, 1000);
    }else{
        setpicks([])  
    }
    
    }else if((data.siteId==null || data.siteId=='' || data.sitePath=='-') && data.mobile!==null && data.mobile!==undefined && data.mobile!==''){
       
       const que = await  getDocs(query(collection(firebase,'vehiclePicks'),where('businessId','==',data.businessId),where('mobile','==',data.mobile)))
        if(!que.empty){
            const arr:any = [];            
            que.docs.forEach(async(vehicle:any)=>{
                const  vehi = vehicle.data()
                const que = await  getDocs(query(collection(firebase,collectionName),
             where('businessId','==',vehi.businessId),
             where('freshOn','>=',date),
             where('make','==',vehi['make'])
            //  where('model','in',vehi['modelsGroup'])
             ))      
             const arrx:any = [];
              if(!que.empty){    
                                       
                  que.docs.forEach((v:any)=>{                        
                             
                    try {
                    if(v.data()['year']<=vehi['yearMax'] && v.data()['year']>=vehi['yearMin']){
                        const modell =(v.data()['model']).toUpperCase()
                        if(vehi.modelsGroup!==undefined && vehi.modelsGroup.includes(modell)){
                      arrx.push(v.data())
                        }else if(vehi.model===v.data()['model']){
                            arrx.push(v.data())
                        }
                    }                   
                        
                } catch (error) {
                 console.log(error);
                        
                }

                  })                 
              }
              vehicle.newVehicles=arrx
              arr.push(vehicle)
            })
            setTimeout(() => {
                setpicks(arr)           
              }, 1000);          
        }else{
            setpicks([])  
        }
}



}


const showAvailableVehicles=async(vehicle:any)=>{
    if(window.innerWidth<767 || data.logo==null){
        setpicksShow(false)
        setnewpicksShow(true)
    }    
    setnewVehicles(vehicle)

}

    
const HideAvailableVehicles=()=>{
    if(window.innerWidth<767 || data.logo==null){
    setpicksShow(true)
    setnewpicksShow(false)
    }
}

const getLeftDays=(date:any)=>{
    try {
        

const EndDate= new Date();
let StartDate =  new Date();
    try {
        
        if(date!==undefined){
        StartDate=new Date(date.seconds*1000);
        }else{
            new Date(date);
        }
    } catch (error) {
      console.log(error);

    }

    // Convert both dates to milliseconds
    const date1_ms = StartDate.getTime()
    const date2_ms = EndDate.getTime()

  // To calculate the time difference of two dates 
  const Difference_In_Time = date2_ms - date1_ms; 
      
  // To calculate the no. of days between two dates 
  const Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24); 
return Math.round(Difference_In_Days)
} catch (error) {
    console.log(error);
           return 0 
}
}

const deletePick=async(id:any)=>{
   await deleteDoc(doc(firebase,'vehiclePicks',id));
        getPicks()
}

const RefreshPick=async(id:any)=>{
    await updateDoc(doc(firebase,'vehiclePicks',id),{timestamp:new Date()});
    getPicks()
}

const openInfo=(v:any)=>{ 
         console.log(v,'9999999999999999');
         
         if(data.buisness.StockImageUrl!==undefined && data.buisness.StockImageUrl!==null && data.buisness.StockImageUrl!==''){
            if(v.vstockno!==undefined && v.vstockno!==null){
               const  url =data.buisness.StockImageUrl.replaceAll('xxxxx', v.vstockno);
                v.ImageurlsFinal = [url] 
            }
            
         }
      
    setinfo(v)

    setinfomodal123(true)
}

const setlangfinal=()=>{
    if(langstr==='eng'){ setlang(strings.esp); setlangstr('esp') }else{   setlang(strings.eng);  setlangstr('eng')}
}

const finalStyle = {
    background:data.theme.jiSignUpUiBackgroundColor!==undefined && data.theme.jiSignUpUiBackgroundColor!=='' ? data.theme.jiSignUpUiBackgroundColor : '',
    color:data.theme.jiSignUpUiFontColor!==undefined && data.theme.jiSignUpUiFontColor!=='' ? data.theme.jiSignUpUiFontColor : 'rgb(9, 104, 170)',
    fontWeight:'bold',
    }


    const imagescreate=(images:any)=>{
        const img:any =[];
         images.filter((x:any)=>{
     
     img.push({original:x,thumbnail:x})
     
         })
         return img
     }
    return (<div>
   {data.logo!==null && data.busienssPath !== 'go-pull-it-atlanta-east' && data.busienssPath !== 'go-pull-it'  ? <div className="d-flex mrl-50">
    <div>
     <H1 className="pickPageHeading" style={finalStyle}>Just In At <br/> {site.siteName!==undefined ? site.siteName:''}</H1>  
     </div>
     <img
     className="logoLandingvehicle"
style={{
    // width:data.widthForDesktop,height:data.heightForDesktop,
    border:data.kioskUiLogoBorderColor!=='' ? `1px solid ${data.kioskUiLogoBorderColor}` :'none'}}
src={data.logo} />
      
    </div>:''}
    <div className="d-flex mrl-50" style={{    flexWrap: 'wrap'}}>
    <Button intent="success" className="mb-10" onClick={()=>setaddnewpickmodal(true)}>{lang.addNewPick}</Button>&nbsp;
   {data.enableExpanolBtn? <Button intent="success"className="mb-10" onClick={()=> setlangfinal()}>{lang.toggleLanguage}</Button>:''}&nbsp;
    <Button intent='primary' className="mb-10 helpbutton" onClick={()=>setHelpmodal(true)}>{lang.helpTxt}</Button>
    </div>
    <div className="d-flex-Row ">
    <div className="col-50v" style={{display:picksShow?'block':'none',width:data.logo==null ? '90%': ''}}>
        <table border={1} style={{ borderCollapse: 'collapse',background:'aliceblue',color:'black'}}>
            <thead>
                <tr>
                    <th>{lang.remove}</th>
                    <th>{lang.savedVehicles}</th>
                    <th>{lang.updates}</th>
                    <th>{lang.refreshTo90Days}</th>
                </tr>
            </thead>
            <tbody>
               {picks[0]!==undefined ? 
               picks.map((vehicle:any,i:any)=>(vehicle.data()!==undefined? <tr key={i}>
                    <td><Icon onClick={()=>deletePick(vehicle.id)} className="cursor-p" icon='delete' intent={"danger"} size={25}/></td>
                    <td className="font-pick" onClick={()=>showAvailableVehicles(vehicle.newVehicles)}>{vehicle.data().yearMin}-{vehicle.data().yearMax} {vehicle.data().combined}</td>
                    <td className="pp-0" onClick={()=>showAvailableVehicles(vehicle.newVehicles)}>
                        <p > <Icon icon={vehicle.newVehicles.length<=0? 'unknown-vehicle':'known-vehicle' } intent={vehicle.newVehicles.length<=0?"danger":'success'} size={25}/></p>
                           <p> {90-getLeftDays(vehicle.data().timestamp)>=0 ? (90-getLeftDays(vehicle.data().timestamp)) :0}d left </p>
                          {vehicle.newVehicles.length<=0? <p>{lang.noFreshStock}</p>: vehicle.newVehicles.length+' new '+(vehicle.newVehicles.length==1? 'vehicle': 'vehicles')}
                        </td>
                    <td><Icon className="cursor-p" icon='refresh' onClick={()=>RefreshPick(vehicle.id)} intent="success"/></td>                  
                </tr> :'')) : <tr ><td colSpan={4}>	Press the green 'add' button to add a new pick</td></tr> }
            </tbody>
        </table>
    </div>
    <div className="col-50v" style={{display:newpicksShow? 'block': 'none',width:data.logo==null ? '90%':''}}>
        <table border={1} style={{ borderCollapse: 'collapse',background:'aliceblue',color:'black'}}>
            <thead>
                <tr>
                    <th>{window.innerWidth<767 || data.logo==null ?<span style={{float:'left'}}><Icon icon='arrow-left' size={20} onClick={()=>HideAvailableVehicles()}/></span>:''}{lang.recentAdditions}</th>
                    <th>{lang.details}</th>
                </tr>
            </thead>
            <tbody>
               {newVehicles[0]!==undefined ? newVehicles.map((x:any,i:any)=>( <tr key={i}>
                    <td className="font-pick">{x.year} {x.make} {x.model} @ {x.path} {x.sitePath} - {getLeftDays(x.freshOn)}d ago</td>
                    <td><Icon className="cursor-p font-pick" icon='eye-open' onClick={()=>openInfo(x)} size={20} intent='primary'/></td>                  
                </tr>)) :<td className="font-pick">{lang.noFreshStock}</td>}
            </tbody>
        </table>
        </div>
    </div>
    


    <Dialog  title={''} isOpen={infomodal123} onClose={()=>setinfomodal123(false)} style={finalStyle}>
    <DialogBody >
<H2 className="text-center" style={finalStyle}>{lang.vehicleInformation}</H2>
{info.year!==undefined?

<div>

    <ul>
  {info.sitePath!==undefined && info.sitePath!==null ?  <li style={{padding: 5, margin: 5}}><b style={{fontSize:'14px'}}>{'Location'}</b>: <span style={{background:'rgb(235 235 235)', color:' #373737',fontWeight: 500,padding:' 2px'}}>{  info.sitePath.toUpperCase()}</span></li>:''}
    <li style={{padding: 5, margin: 5}}><b style={{fontSize:'14px'}}>{lang.year}</b>: <span style={{background:'rgb(235 235 235)', color:' #373737',fontWeight: 500,padding:' 2px'}}>{info.year}</span></li>
    <li style={{padding: 5, margin: 5}}><b style={{fontSize:'14px'}}>{lang.make}</b>: <span style={{background:'rgb(235 235 235)', color:' #373737',fontWeight: 500,padding:' 2px'}}>{info.make}</span></li>
    <li style={{padding: 5, margin: 5}}><b style={{fontSize:'14px'}}>{lang.model}</b>: <span style={{background:'rgb(235 235 235)', color:' #373737',fontWeight: 500,padding:' 2px'}}>{info.model}</span></li>
{collectionName!='hollanderUrg'?    <li style={{padding: 5, margin: 5}}><b style={{fontSize:'14px'}}>{collectionName!='yardsmartfeed'? lang.rowNumber :'Yard Location'}</b>: <span style={{background:'rgb(235 235 235)', color:' #373737',fontWeight: 500,padding:' 2px'}}> {collectionName!='yardsmartfeed' && info.rowNo!=undefined? info.rowNo :info.location}</span></li>:''}
 
   {/* {info.engine ? <li style={{padding: 5, margin: 5}}><b style={{fontSize:'14px'}}>{lang.engineDisplacement}:</b>: <span style={{background:'rgb(235 235 235)', color:' #373737',fontWeight: 500,padding:' 2px'}}>{info.engine}</span></li>:''} */}
   {info.drive ?  <li style={{padding: 5, margin: 5}}><b style={{fontSize:'14px'}}>{lang.drivetrain}:</b>: <span style={{background:'rgb(235 235 235)', color:' #373737',fontWeight: 500,padding:' 2px'}}>{info.drive}</span></li>:''}
   {info.trans ?  <li style={{padding: 5, margin: 5}}><b style={{fontSize:'14px'}}>{lang.transmission}:</b>: <span style={{background:'rgb(235 235 235)', color:' #373737',fontWeight: 500,padding:' 2px'}}>{info.trans}</span></li>:''}
   {info.mileage ?  <li style={{padding: 5, margin: 5}}><b style={{fontSize:'14px'}}>{lang.mileage}:</b>: <span style={{background:'rgb(235 235 235)', color:' #373737',fontWeight: 500,padding:' 2px'}}>{info.mileage}</span></li>:''}

    <li style={{padding: 5, margin: 5}}><b style={{fontSize:'14px'}}>{lang.stockNumber}</b>: <span style={{background:'rgb(235 235 235)', color:' #373737',fontWeight: 500,padding:' 2px'}}>{info.vstockno}</span></li>
    <li style={{padding: 5, margin: 5}}><b style={{fontSize:'14px'}}>VIN:</b>: <span style={{background:'rgb(235 235 235)', color:' #373737',fontWeight: 500,padding:' 2px'}}>{info.vin_no}</span></li>
    <li style={{padding: 5, margin: 5}}><b style={{fontSize:'14px'}}>{lang.added}</b>: <span style={{background:'rgb(235 235 235)', color:' #373737',fontWeight: 500,padding:' 2px'}}>{info.freshOn.seconds!==undefined ? new Date((info.freshOn.seconds*1000)).toLocaleDateString() :''}</span></li>

    </ul>
</div>


:''}
{(collectionName=='yardsmartfeed'  || info!==undefined && info!==null && info.ImageurlsFinal!==undefined && info.ImageurlsFinal.length>0) ? 
<div  style={{display:'flex'}}>
   {info!==undefined && info!==null && info.ImageurlsFinal!==undefined && info.ImageurlsFinal.length>0 ? <ImageGallery items={imagescreate(info.ImageurlsFinal)} />:''} 
</div>
:''}
</DialogBody>
    </Dialog>

    <Dialog title={lang.helpTxt}  isOpen={Helpmodal} onClose={()=>setHelpmodal(false)}>
    <DialogBody>
    <p dangerouslySetInnerHTML={{__html:lang.helpTxtDesc}}></p>
    </DialogBody>
</Dialog>

<Dialog  isOpen={addnewpickmodal} onClose={()=>setaddnewpickmodal(false)}>
    <DialogBody className="text-center">
   <MakeModelYearInput
     lang={langstr}
     callBack={(e:any,str:any)=>{setlangstr(str); setlang(e)}}
     showVehiclePicks={(e:any)=>{setaddnewpickmodal(false); getPicks();}}
     setMobilex={(e:any)=>setMobilex(e)}
     businessId={data.businessId}
     mobile={data.mobile}
     siteId={data.siteId}
     busienssPath={data.busienssPath}
     sitePath={data.sitePath}
     theme={data.theme}
     frompicks={true}
   />
    </DialogBody>
</Dialog>
        </div>
    )
}