import * as React from "react";
import '../../assets/css/justinmultilocation.css'
import { Button, ButtonGroup, Dialog, H1,H3, H4, H6, Icon, InputGroup,MenuItem, Label, Spinner } from "@blueprintjs/core";
import { orderBy, range } from "lodash";
import makes from "../../components/justInComponents/makes";
import VeiclePicksDashboard from './VeiclePicksDashboard'
import { firebase } from "../../global/firebase";
import { addDoc, collection, getDocs, query, where } from "firebase/firestore";
import { Select } from "@blueprintjs/select";
class JustInMulticationLocation extends React.Component{
    public currentYear =new Date().getUTCFullYear()
    public state={
        homebuttonurl:'',showhomebutton:false,
        makequery:'',
        modelquery:'',
        openHelp:false,
        errordash:'',
        mobiledash:'',
      loading:true,
      showDefaultPage:true,
      showAddPickScreen:false,
      showUrlEror:false,
      business:{id:null,name:'',logo:'',path:'',s3id:'',urgid:''},
      sites:[],
      spinner:false,
      edit:false,
      mobile:'',
      error:'',
      makesList:[],
      makeError:'',
      modelError:'',
      modelsList:[],
      locationError:'',
      makes:[],
      models:[],
      make:null,
      model:null,
      minYear:1960,
      maxYear:this.currentYear,
      showVehiclePicks:false,
      years:[],
      selectedSites:[],
      vehiclePicks:[{
                    make:null,
                    model:null,
                    minYear:null,
                    maxYear:null,
                    mobile:null,
      }]   

    }


 public   componentDidMount(): void {
    this.setState({makesList:makes})
    setTimeout(() => {
        const main = document.querySelector<HTMLElement>('body')!;
        main.style.paddingTop='10px'           
        main.style.background='#ffffff' 
    }, 500);
        const max = new Date().getUTCFullYear();
        const min = 1960;
        const yearRange = range(min, max + 1);
        this.setState({years:yearRange});

       const url = window.location.pathname.split('/');
       if(url[2]!==undefined){
        this.getBusinessData(url[2]);
       }else{
            
        this.setState({showUrlEror:true})
    }

       if(url[3]!==undefined){ 
        try {
        
            if(url[3].length===10 && typeof parseInt(url[3],10) ==='number'){
                this.setState({mobile:url[3],showVehiclePicks:true});
            }  
                
        } catch (error) {
            console.log(error);
            
        }     
       }


    }

public getBusinessData=async(business:any)=>{
    const data =await getDocs(query(collection(firebase,`businesses`),where('path','==',business))); 
        if(!data.empty){
            const sites =await getDocs(query(collection(firebase,`businesses/${data.docs[0].id}/sites`))); 
            // BUISNESS SETTING
            const final =data.docs[0].data();
            let logo= ''
            if(final.theme!==undefined && final.theme.logo!==undefined){
                logo= final.theme.logo
            } 
            // SITE SETTING  
            const arr:any = [];   
            let showhomebutton = false;
            let homebuttonurl ='' ;  
            if(!sites.empty){
                sites.docs.map((v:any)=>{
                 const siteData=v.data();
               if(siteData.homeBtnEnable!==undefined && siteData.homeBtnEnable==true){
                showhomebutton=true
                if(siteData.homeBtnUrl!==undefined && siteData.homeBtnUrl!=''){
                    homebuttonurl=siteData.homeBtnUrl
                }
               }
                 
                 const s3ids= siteData.s3Id!==undefined ?  siteData.s3Id :'';
                 const urgids= siteData.urgId!==undefined ? siteData.urgId :'';
                 arr.push({id:v.id,name:siteData.siteName,path:siteData.path,s3id:s3ids,urgid:urgids})
                })
            }else{
                this.setState({showUrlEror:true}) 
            }
            const s3id= final.s3Id!==undefined ?  final.s3Id :'';
            const urgid= final.urgId!==undefined ? final.urgId :'';
             this.setState({homebuttonurl,showhomebutton,business:{s3id,urgid,id:data.docs[0].id,path:business,name:final.businessName,logo},sites:arr,loading:false})   
        }else{
            
            this.setState({showUrlEror:true,loading:false})
        }
    
}


public updateMinYear=(e:any)=>{
        this.setState({minYear:parseInt(e.target.value,10)})
        this.getModels(this.state.make,parseInt(e.target.value,10),this.state.maxYear)
        
    }
public updateMaxYear=(e:any)=>{
    this.setState({maxYear:parseInt(e.target.value,10)})
         this.getModels(this.state.make,this.state.minYear,parseInt(e.target.value,10))   
    }


public getModels=async(v:any,min:any,max:any)=>{   

    this.setState({make:v})   
   
    const data =  await getDocs(query(collection(firebase,`vehicles`),where('make','==',v),where('year','<=',max),where('year','>=',min)));
    const arr:any=[];
    if(!data.empty){
        data.docs.map((x:any)=>{  
            try {           
                if(x.data().make==='BMW' || x.data().make==='MERCEDES-BENZ'){
                    arr.push(x.data().groupName)
                }else{
                    arr.push(x.data().model)
                }                    
            } catch (error) {
                console.log(error);            
            }
        })  
        const arrUnique =  await arr.filter((value:any, index:any, array:any) => array.indexOf(value) === index)          
        const arrUniquex =  await arrUnique.sort();
        this.setState({models:arrUniquex,modelsList:arrUniquex});

    }else{
       this.setState({models:arr,modelsList:arr,model:null});
    }
}

public mobileChange=(e:any)=>{   
    document.querySelector<HTMLElement>('#inputnum1')!.style.color='#000';

    if(e.length<=10){
        this.setState({mobile:e});
    }
    if(e.length>=10){
        document.querySelector<HTMLElement>('#inputnum1')!.style.color='#22AC57';
    }
}

public mobileChangemobiledash=(e:any)=>{   
    if(e.length<=10){
        this.setState({mobiledash:e})
    }
}






public onlyUnique(value:any, index:any, array:any) {
    return array.indexOf(value) === index;
  }


public addPick=async()=>{
    this.setState({spinner:true})

    const array:any=[]
    if(this.state.make==='BMW' || this.state.make==='MERCEDES-BENZ'){
        
        const datas =await getDocs(query(collection(firebase,`vehicles`),where('make','==',this.state.make),where('groupName','==',this.state.model),where('year','>=',this.state.minYear),where('year','<=',this.state.maxYear)));


      datas.docs.map((x:any)=>{
            array.push(x.data().model)
      }) 

    }else{
      array.push(this.state.model)
    }
    
const finalarray =  array.filter(this.onlyUnique);
const mobile =this.state.mobile;

let alertCount = 0
this.setState({modelError:'',makeError:'',locationError:'',error:''});
if(this.state.selectedSites.length<=0 && (this.state.make===null || this.state.make==='') &&  ( this.state.model===null || this.state.model==='') && (mobile===null || mobile.length<=10 || mobile==='')){
    alertCount +=1;
   alert('Please ensure you select a Make, Model, Location(s), and US phone number.');
   this.setState({modelError:'Please choose a Model.',makeError:'Please choose a Make.',spinner:false,error:'Please provide a mobile number consisting of 10 digits.',locationError:'Please select your preferred location(s)'});

}


if((this.state.selectedSites.length<=0 && (this.state.make===null || this.state.make==='') &&  ( this.state.model===null || this.state.model==='')) && alertCount<=0){
        alertCount +=1;
        alert('Please ensure you select a Make, Model, and Location(s)');
        this.setState({modelError:'Please choose a Model.',makeError:'Please choose a Make.',spinner:false,locationError:'Please select your preferred location(s)'});
    }


    if(((this.state.make===null || this.state.make==='') && (this.state.model===null || this.state.model==='') ) &&  (mobile===null || mobile.length<10 || mobile==='') && alertCount<=0){
        alertCount +=1;
        alert('Please ensure you select a Make and Model and US phone number before proceeding to the next step.');
        this.setState({makeError:'Please choose a Make.',error:'Please provide a mobile number consisting of 10 digits.',modelError:'Please choose a Model.',spinner:false,});
    }

if(((this.state.model===null || this.state.model==='') && this.state.selectedSites.length<=0 )&&  (mobile===null || mobile.length<10 || mobile==='') && alertCount<=0){
        alertCount +=1;
        alert('Please ensure you select a Model, Location(s) and US phone number.');
        this.setState({modelError:'Please choose a Model.',locationError:'Please select your preferred location(s)',error:'Please provide a mobile number consisting of 10 digits.',spinner:false});
    }

    if(( this.state.selectedSites.length<=0 ) &&  (mobile===null || mobile.length<10 || mobile==='') && alertCount<=0){
        alertCount +=1;
        alert('Please ensure you select a Location(s), and US phone number.');
        this.setState({locationError:'Please select your preferred location(s)',error:'Please provide a mobile number consisting of 10 digits.',spinner:false});
    }


    
if(((this.state.model===null || this.state.model==='') && this.state.selectedSites.length<=0 )&& alertCount<=0){
    alertCount +=1;
    alert('Please ensure you select a Model and preferred Location(s).');
    this.setState({modelError:'Please choose a Model.',locationError:'Please select your preferred location(s)',spinner:false});
}


if(((this.state.model===null || this.state.model==='')  &&  (mobile===null || mobile.length<10 || mobile==='') )&& alertCount<=0){
    alertCount +=1;
    alert('Please ensure you select a Model and US phone number.');
    this.setState({modelError:'Please choose a Model.',error:'Please provide a mobile number consisting of 10 digits.',spinner:false});
}





if(((this.state.make===null || this.state.make==='') && (this.state.model===null || this.state.model==='') )&& alertCount<=0){
    alertCount +=1;
    alert('Please ensure you select a Make and Model before proceeding to the next step.');
    this.setState({makeError:'Please choose a Make.',modelError:'Please choose a Model.',spinner:false,});
}

if((this.state.make===null || this.state.make==='') && alertCount<=0){
    alertCount +=1;
    alert('Please ensure you select a Model before proceeding to the next step.');
    this.setState({makeError:'Please choose a Make.',spinner:false,});
}


if(((this.state.make===null || this.state.make==='') && this.state.selectedSites.length<=0) && alertCount<=0){
    alertCount +=1;
    alert('Please ensure you select a Make and preferred Location(s) before proceeding to the next step.');
    this.setState({makeError:'Please choose a Make.',locationError:'Please select your preferred location(s).',spinner:false,});
}


if((this.state.model===null || this.state.model==='') && alertCount<=0){
        alertCount +=1;
        alert('Before proceeding to the next step, please be sure to select a Model.');
        this.setState({modelError:'Please choose a Model.',spinner:false});
}
if(this.state.selectedSites.length<=0 && alertCount<=0){
    alertCount +=1;
    alert('Please ensure you select a preferred Location(s)');
    this.setState({locationError:'Please select your preferred location(s).',spinner:false});
} 

if((mobile===null || mobile.length<10 || mobile==='') && alertCount<=0){
    alertCount +=1;
    alert('Please ensure you enter your US phone number.');
    this.setState({error:'Please provide a mobile number consisting of 10 digits.',spinner:false});
}

if(alertCount<=0){    
    this.setState({modelError:'',makeError:'',locationError:'',error:''});

    const UniqueKey = new Date().getTime();
    this.state.selectedSites.map(async(r:any)=>{        
    const  pick ={    
        UniqueKey,
        businessId:this.state.business.id,
        combined:this.state.make+' '+this.state.model,
        firstName:'',
        make:this.state.make,
        mobile,
        model:this.state.model,
        modelsGroup:finalarray,
        siteId:r,
        textSent:false,
        userId:null,
        yearMax:this.state.maxYear,
        yearMin:this.state.minYear,
        timestamp:new Date(),
    }


    if(mobile!==null && mobile.length===10 && mobile!=='' &&  this.state.business.id!==undefined &&  this.state.business.id!==null){
        await addDoc(collection(firebase, "vehiclePicks"), pick)

       
            const re= this.state.sites.filter((x:any)=>{
              x.checked = false ;        
              return x
            });

       this.setState({sites:re,spinner:false,showVehiclePicks:true,showAddPickScreen:false,make:null,model:null,showDefaultPage:false,selectedSites:[]})
    }else{
        alert('Please provide a United States mobile number consisting of 10 digits.')
        this.setState({spinner:false,error:'Please provide a mobile number consisting of 10 digits.'})
    }

    })

}
    }

public addSiteInpick=(sId:any,e:any)=>{
    const sit:any =[] ;  
        if(e.target.checked===true){   
            sit.push(sId)   
            this.state.selectedSites.map((x)=>{                      
                sit.push(x)
            });
        }else{            
            this.state.selectedSites.map((x)=>{   
                if(sId!==x){                   
                    sit.push(x)
                }
            });
        }
    this.setState({selectedSites:sit});
}

public openDashboard=()=>{
    const mobiledash =this.state.mobiledash;
    if(mobiledash!==null && mobiledash.length===10 && mobiledash!=='' ){
let slash ='/';
if(window.location.href.split('/').length===6){
    slash ='';
}
        
window.location.href = window.location.href+slash+mobiledash
}else{
    this.setState({spinner:false,errordash:'Mobile Field is required*'})
}
}

public filterModels=(e:any)=>{
    this.setState({modelquery:e})
    const sear = e.toUpperCase()
    const arr =   this.state.models.filter((x:any)=>{
           if(x.match(sear)){
               return true;
           }
           return false;
       });
       this.setState({modelsList:arr})
   
   }

public filterMakes=(e:any)=>{
    this.setState({makequery:e})
    const sear = e.toUpperCase()
    const arr =   makes.filter((x:any)=>{
           if(x.match(sear)){
               return true;
           }
           return false;
       });
       this.setState({makesList:arr})
   
   }

public render(){
    const {homebuttonurl,showhomebutton,years,minYear,maxYear,makesList,models,make,modelsList,mobile,spinner,showUrlEror,sites,business,showVehiclePicks,loading,error,openHelp,showDefaultPage,model,errordash,mobiledash,makeError,modelError,locationError}=this.state; 
    
    return(
        <div className="contain-Main-ml" >

{showhomebutton ?<Button onClick={()=>window.open(homebuttonurl)} style={{position:'absolute',left:5,top:5}} intent="danger">
    <Icon icon='home' size={20} />
</Button>:''}
           {showUrlEror ? 
           <div>
             <div className="ml-logo-container">
                Your Url Is  incorrect. Please contact with texn rewards team and get valid url.
                </div>
           </div>
           : showVehiclePicks && business.id!==null ?
           <VeiclePicksDashboard  data={this.state} return={(e:any)=>this.setState(e)} />
           : 
           
           !loading ?  
           <div>
            <div className="ml-logo-container">
                <img className="ml-logo" style={{width:business.name.includes('Chesterfield')?'120px':''}} src={this.state.business.logo!=='' ? this.state.business.logo : 'https://just-in.texnrewards.com/favicon.ico'}/>
            </div>
        <div className="bordercontainer" style={{position:'relative'}}>
            <div className="main-ml-screen" >
            <Icon className="conicon" iconSize={25} intent="primary"  onClick={()=>this.setState({openHelp:true})} icon='info-sign'/>
                    <H1 className="h1in">{business.name.includes('Chesterfield') ?'Wish List Alerts at' :'Just In Vehicle Alerts At'} {business.name}</H1>
                    <H3 className="h3in">{business.name.includes('Chesterfield') ? 'Sign up to receive text alerts every evening when new vehicles matching your criteria are added.' :'Get alerts for newly-added vehicles directly to your phone!'}</H3>
                    <H3 className="h3in">{business.name.includes('Chesterfield')  ? "Set Your Preferences Below" : "We'll text you as soon as they're ready."}</H3>
            </div>
            <div className="main-ml-screen">
                    <H4>Year Range</H4>
                    <div className="d-flex">
                        <div className="col-50">
                                <select className="w-13" onChange={(e)=>this.updateMinYear(e)}>
                                {years.sort((a:any,b:any)=>(a-b)).map((v:any,i:any)=><option key={i} value={v}>{v} </option>)}
                                </select>
                        </div>
                        <div className="col-50"><H3 style={{padding:'10px'}}>to</H3></div>
                        <div className="col-50">
                                <select className="w-13"  onChange={(e)=>this.updateMaxYear(e)}>
                                {years.sort((a:any,b:any)=>(b-a)).map((v:any,i:any)=>v>=minYear?<option key={i} value={v}>{v} </option>:'')}
                                </select>
                                
                        </div>
                    </div>
            </div>

                <div className="main-ml-screen">
                    <div className="w-100">
                        <H4>Make</H4>
                    <Select
                    query={this.state.makequery}
                    onQueryChange={(e:any)=>this.filterMakes(e) }
                    items={makesList}
                    noResults={'Make Not Found'}
                    itemRenderer={(val, itemProps) => { 
                    return ( 
                    <MenuItem 
                    key={val} 
                    text={val} 
                    onClick={(elm:any) => {  
                    this.setState({model:null,makequery:''});   
                    console.log(elm.target.innerText,'okko');
                      
                    this.getModels(elm.target.innerText,minYear,maxYear)
                    }} 
                    active={itemProps.modifiers.active} 
                    /> 
                    ); 
                    }}           
                    onItemSelect={(r)=>{console.log(r);  }} 
                    > 
        <Button className="w-13 buttonheight" text={make!==null ? make :'SELECT MAKE'} rightIcon="caret-down" /> 
</Select> 
                        <p style={{color:'red'}}>{makeError}</p>
                    </div>
                    <div className="w-100">
                    <H4>Model</H4>
     <Select
     query={this.state.modelquery}
onQueryChange={(e:any)=>this.filterModels(e) }
  items={modelsList}
  noResults={make===null ?'Please choose a make before selecting a model.':'Model Not Found'}
  itemRenderer={(val, itemProps) => { 
                    return ( 
                        <MenuItem 
                            key={val} 
                            text={val} 
                            onClick={(elm:any) => { 
                              this.setState({model:elm.target.innerText,modelquery:''});

                            }} 
                            active={itemProps.modifiers.active} 
                        /> 
                    ); 
                }} 
                onItemSelect={(r)=>{console.log(r); }} 
            > 
        <Button className="w-13 buttonheight"  text={model!==null ? model :'SELECT MODEL'} rightIcon="caret-down" /> 
</Select> 
                        <p style={{color:'red'}}>{modelError}</p>
                    </div>
                </div>

                <div className="main-ml-screen">
                <H4>Locations</H4>
                <div style={{textTransform:'capitalize',marginBottom:'10px'}}>
                                {/* {business.path==='fenix-u-pull' ? 'ALL 3 NY Locations' : 'Select All'} */}
                                Check all that you would like to register for:
                </div>
                   {sites.map((s:any)=>( 
                        <div style={{display:'block'}}>
                                    <Label className="labelml">
                                    <span><input onClick={(e)=>this.addSiteInpick(s.id,e)} value={s.id} type="checkbox"/></span>
                                    <span style={{textTransform:'capitalize',fontWeight:'500'}}>&nbsp;&nbsp;{s.name}</span>
                                
                                    </Label>
                        </div>
                    ))}
                      <p style={{color:'red'}}>{locationError}</p>
                  
                </div>
                <div className="main-ml-screen">

            {(mobile!==undefined && mobile!==null && mobile!=='' && showVehiclePicks) || !showDefaultPage ? '' : <H4 className='h22 mt-20'  >Mobile Number</H4>}
            <InputGroup
            style={{width:'150px'}}
            id="inputnum1"
            type={(mobile!==undefined && mobile!==null && mobile!=='' && showVehiclePicks) || !showDefaultPage? 'hidden' :'tel'}
            className='inputForMobile'
            value={mobile ? mobile :''}
            onChange={(e)=>this.mobileChange(e.target.value)}
            />
               {(mobile!==undefined && mobile!==null && mobile!=='' && showVehiclePicks) || !showDefaultPage ? '' :    <p style={{color:'red'}}>{error}</p>}
            <ButtonGroup>
          {(mobile!==undefined && mobile!==null && mobile!=='' && showVehiclePicks) || !showDefaultPage ? <Button className='mt-2' intent='warning' disabled={spinner} onClick={()=>this.setState({showDefaultPage:false,showVehiclePicks:true})} large={true}><Icon icon='key-backspace' />&nbsp;&nbsp;&nbsp;{'Back'}</Button>:''}&nbsp;&nbsp;
            <Button className='mt-2' intent='success' disabled={spinner} onClick={()=>this.addPick()} large={true}><Icon icon='log-out' />&nbsp;&nbsp;&nbsp;{'Next'}</Button>
            </ButtonGroup>

            <br/><br/>
{(mobile!==undefined && mobile!==null && mobile!=='' && showVehiclePicks) || !showDefaultPage ? '' : <p className="tnc_sign_up_block">By selecting Next, you are signing up to receive automated text alerts and marketing calls from an autodialer system or live person.
 Consent to receive automated text alerts not required for purchase.
  You'll receive up to 1 message per day for each selected location. Data Rates May Apply. Reply HELP for HELP, STOP ALL to cancel.
    <a href="https://client.texnrewards.net/terms_of_use.asp" target="_blank">Ts &amp; Cs / Privacy Policy</a></p>}
            <br/>
{/* return  */}
<div className={(mobile!==undefined && mobile!==null && mobile!=='' && showVehiclePicks) || !showDefaultPage ? '' : "bordercontainer"}>
        {(mobile!==undefined && mobile!==null && mobile!=='' && showVehiclePicks) || !showDefaultPage ? '' : <H3 className='h22return mt-20'  >Returning to this page?</H3>}
        {(mobile!==undefined && mobile!==null && mobile!=='' && showVehiclePicks) || !showDefaultPage ? '' :<H6>Enter your mobile number to access dashboard</H6>}
            <InputGroup
              id="inputnum2"
            style={{width:'150px'}}
            type={(mobile!==undefined && mobile!==null && mobile!=='' && showVehiclePicks) || !showDefaultPage? 'hidden' :'tel'}
            className='inputForMobile'
            value={mobiledash ? mobiledash :''}
            onChange={(e)=>this.mobileChangemobiledash(e.target.value)}
            />
               {(mobile!==undefined && mobile!==null && mobile!=='' && showVehiclePicks) || !showDefaultPage ? '' :    <p style={{color:'red'}}>{errordash}</p>}
         { (mobile!==undefined && mobile!==null && mobile!=='' && showVehiclePicks) || !showDefaultPage ? '' : 
           <Button className='mt-2' intent='success' disabled={spinner} onClick={()=>this.openDashboard()} large={true}><Icon icon='log-out' />&nbsp;&nbsp;&nbsp;{'Next'}</Button>}
</div>

</div>

            </div>
     </div>
: <div className="main-auto-center"><Spinner intent="primary" size={20}/></div>
}







<Dialog icon={'info-sign'} title= {'How It Works'} isOpen={openHelp} onClose={()=>this.setState({openHelp:false})}>
    <p style={{padding:'10px'}}>
    If you are revisiting this page and need to access your previous vehicle selections, simply enter your phone number in the designated box below the prompt, 'Returning to this page? Enter your mobile number to access Dashboard.'
    <br/><br/>
If this is your first time selecting a vehicle, choose the Make and Model for which you wish to receive notifications. The Year range is optional.
<br/><br/>
After selecting your vehicle, enter a valid mobile number. Then, use the green 'Next' button to access your Dashboard where you can edit, renew, or delete your vehicle picks. 
    </p>
</Dialog>
        </div>
    )
}

}

export default JustInMulticationLocation