import { collection, doc, getDoc, getDocs, setDoc } from "firebase/firestore";
import { firebase, functions } from "../global/firebase";
import * as React from "react";
import { Button, H4, H6, HTMLTable, Icon, Spinner } from "@blueprintjs/core";
import { httpsCallable } from "firebase/functions";
import { useAuthUser } from "react-auth-kit";
import * as XLSX from "xlsx";
import { useSelector } from "react-redux";
const Summaryreportbygroup=()=>{

const [groups,setgroups] =React.useState([] as any);
   const [selectedmonth,setselectedmonth] = React.useState('');
   const [loading,setloading] =React.useState(false);
   const [csv,setcsv] =React.useState('');
   const [groupname,setgroupname]=React.useState('');
   const [datesarray,setdatesarray]=React.useState([] as any)
   const [datesarrayinit,setdatesarrayinit]=React.useState([] as any)
   const auth:any = useAuthUser();
   const [group,setgroup]=React.useState({} as any);
   const getguidContactsSupportedCount = httpsCallable(functions, 'getContacts',{ timeout :500000});
   const finalx:any = useSelector(state=>state);
   const [topheadercol,settopheadercol]=React.useState({
    first:10,
    second:3,
    third:0,
   })
   const user =  auth()
React.useEffect(()=>{

    if(groups.length<=0){
    getgroups();



    const currentDate = new Date();
    const selectElement:any = document.getElementById('last-12-monthsx')!;
    selectElement.innerHTML ='<option value="">All Available Months</option>';
    // Generate last 12 months
    for (let i = 0; i < 3; i++) {
        const option = document.createElement('option');
        const date = new Date(currentDate.getFullYear(), currentDate.getMonth() - i);
        const month = date.toLocaleString('default', { month: 'long' });
        const year = date.getFullYear();
        const value =`${year}-${String(date.getMonth() + 1)}-01`;
        option.value = value;
        option.textContent = `${month} (${year})`;
        selectElement.appendChild(option);
    }

    }

},[groups])


const groupBy=async(va:any,arr:any)=>{
    const final:any= [];
  
  
  if(va=='month' || va=='year'){
     const arrx = arr.sort((a:any,b:any)=>a.month-b.month)
      const grouped = arrx.reduce((acc:any, item:any) => {
       
        // if(item.SitePath=='tulsa' && item.BusinessPath=='aesop-self-serve'){
        //   item.BusinessPath = 'midway-u-pull-it'
        // }
        let guid = '';
        if(finalx.BusinessesReducer.Businesses[0]!==undefined && finalx.BusinessesReducer.Businesses!==null){
          finalx.BusinessesReducer.Businesses.map((b:any)=>{ 
            if(b.path==item.BusinessPath){
            const sites =b.sites!==undefined ? b.sites :[];
             guid =b.guid;
         if(sites.length>0){
            sites.map((ss:any)=>{      
              if((guid==null || guid==undefined || guid=='') && ('guid' in ss && ss.guid!==undefined &&  ss.guid!=='' && item.SitePath==ss.path)){      
                 guid =ss.guid; 

              }
      
            })
          }
                
        }
          })
      
         }
        
        const key = va=='year' ? item.year+'-'+item.SitePath+'-'+item.BusinessPath :  item.month+'-'+item.SitePath+'-'+item.BusinessPath;
        if (!acc[key]) {
          acc[key] = []; // Initialize group if not exists
        }
            
        item.guid = guid;
        acc[key].push(item);
        return acc;
      }, {});
   
      let lastx =''
      let plan =''      
      let used = 0;     
      let _new = 0;
      let _returning=0;
      let _optout=0;
      let _void=0;
      let _recheck=0;
      let _firstreponder = 0;
      let _theft= 0;
      let _totalsms=0;
      let _totoalmms=0;
      let _totalsmsb=0;
      let _totoalmmsb=0;
      let _totalcheckin = 0;
      let ttsms = 0
      let ttmms = 0 
      let busintotal:any = []
      let finalguid= ''
      Object.keys(grouped).map(async(val:any,i:any)=>{ 
       
        arr = {
            BusinessPath:"",
            LastUpdatedDate:'',
            NextUpdateDate:'',
            SitePath:"",
            msgcode:'',
            cash4cars:0,
            dd:"",
            month:'',
            year:'',
            firstresponder:0,
            plan:'',
            new:  0,
            optout:  0,
            recheck : 0,
            smssentjustIn:0,
            smssent:0,
            mmssent:0,
            smssentBusiness:0,
            mmssentBusiness:0,
            smssentkiosken: 0,
            smssentkioskspanish:0,
            theft:0,
            timestamp:0,
            total:0,
            void:0,
            _return:0,
            guid:finalguid
          }
 
            if(lastx!=='' && (lastx!=val.split('-')[0])){

              final.push({
                BusinessPath:"xxxxxx",
                LastUpdatedDate:'',
                NextUpdateDate:'',
                msgcode:'',
                SitePath:"",
                cash4cars:0,
                dd:``,
                month:'',
                year:'',
                plan:plan,
                firstresponder:_firstreponder,
                new: _new,
                optout:  _optout,
                used,
                recheck : _recheck,
                smssentjustIn:0,
                smssent:_totalsms,
                mmssent:_totoalmms,
                smssentBusiness:0,
                mmssentBusiness:0,
                smssentkiosken: 0,
                smssentkioskspanish:0,
                theft:_theft,
                timestamp:0,
                total:_totalcheckin,
                void:_void,
                _return:_returning,
                guid:finalguid
              })

              final.push({
                BusinessPath:"contact",
               dd:arr.dd,
               guid:busintotal
              })
          
              Object.keys(busintotal).map((key:any)=>{        
                busintotal[key].map((v:any)=>{        
                    ttsms+=v._totalsmsb
                    ttmms+=v._totoalmmsb
                  })                           
              });
         
                final.push({
                  BusinessPath:"other", // for other messages who`s stoped messages frm texn
                  LastUpdatedDate:'',
                  NextUpdateDate:'',
                  SitePath:"",
                  msgcode:'',
                  cash4cars:0,
                  dd:``,
                  month:'',
                  year:'',
                  plan:plan,
                  firstresponder:0,
                  used,
                  new:  0,
                  optout:  0,
                  recheck : 0,
                  smssentjustIn:0,
                  smssent:_totalsms,
                  mmssent:_totoalmms,
                  smssentBusiness:ttsms,
                  mmssentBusiness:_totoalmmsb,
                  smssentkiosken: 0,
                  smssentkioskspanish:0,
                  theft:0,
                  timestamp:0,
                  total:0,
                  void:0,
                  guid:finalguid,
                  _return:0,
                })



                used +=(ttsms -used)

               
               
                final.push({
                    BusinessPath:"",
                    LastUpdatedDate:'',
                    NextUpdateDate:'',
                    SitePath:"",
                    msgcode:'',
                    cash4cars:0,
                    dd:``,
                    month:'',
                    year:'',
                    plan:plan,
                    firstresponder:0,
                    used,
                    new:  0,
                    optout:  0,
                    recheck : 0,
                    smssentjustIn:0,
                    smssent:0,
                    mmssent:0,
                    smssentBusiness:0,
                    mmssentBusiness:0,
                    smssentkiosken: 0,
                    smssentkioskspanish:0,
                    theft:0,
                    timestamp:0,
                    total:0,
                    void:0,
                    _return:0,
                    guid:finalguid
                  })

                  ttsms = 0
                  ttmms = 0 
                  busintotal =[]    
                
                             
                    _new = 0;
                    _returning=0;
                    _optout=0;
                    _void=0;
                    _recheck=0;
                    _firstreponder = 0;
                    _theft= 0;
                    _totalsms=0;
                    _totoalmms=0;

              
                    _totalcheckin = 0;
                    used= 0
               }  

            lastx= val.split('-')[0];
          
        grouped[val].map((item:any)=>{
              
          _new +=item.new
          _returning +=item._return
          _optout +=item.optout
          _void +=item.void
          _recheck +=item.recheck
          _firstreponder +=item.firstresponder
          _theft +=item.theft
          _totalsms +=item.smssent
          _totoalmms +=item.mmssent
          _totalcheckin +=item.total          
          _totalsmsb+=item.smssentBusiness;
          _totoalmmsb+= isNaN(item.mmssentBusiness) ? 0: item.mmssentBusiness;
         
          if (!busintotal[item.guid]) {
            busintotal[item.guid] = [];
          }

          if(busintotal[item.guid].length<grouped[val].length){
          busintotal[item.guid].push({_totalsmsb:item.smssentBusiness,_totoalmmsb: item.mmssentBusiness})
          }
           
          finalguid =item.guid;
          arr.BusinessPath=item.BusinessPath
          arr.guid=item.guid
          arr.LastUpdatedDate=item.LastUpdatedDate
          arr.NextUpdateDate=item.NextUpdateDate
          arr.msgcode =item.msgcode
          arr.SitePath=item.SitePath
          arr._return+=item._return
          arr.cash4cars+=item.cash4cars
          arr.dd=`${item.year}/${item.month}`
          arr.month=item.month
          arr.year=val
          arr.firstresponder+=item.firstresponder
          arr.new+=item.new
          arr.optout+=item.optout
          arr.plan =item.plan

          plan =item.plan          
          arr.recheck+=item.recheck
          arr.smssent+=item.smssent
          arr.mmssent+=item.mmssent
          arr.smssentBusiness=_totalsmsb
          arr.mmssentBusiness=_totoalmmsb
          arr.smssentkiosken+=item.smssentkiosken
          arr.smssentkioskspanish+=item.smssentkioskspanish
          arr.theft+=item.theft
          arr.timestamp=item.timestamp
          arr.total+=item.total
          arr.void+=item.void   
         used+=((item.smssent)+(item.mmssent*2))          
        })

        final.push(arr)
        
        if((Object.keys(grouped).length-1)==i){

            final.push({
                BusinessPath:"xxxxxx",
                LastUpdatedDate:'',
                NextUpdateDate:'',
                msgcode:'',
                SitePath:"",
                cash4cars:0,
                dd:``,
                month:'',
                year:'',
                plan:plan,
                firstresponder:_firstreponder,
                new: _new,
                optout:  _optout,
                used,
                recheck : _recheck,
                smssentjustIn:0,
                smssent:_totalsms,
                mmssent:_totoalmms,
                smssentBusiness:0,
                mmssentBusiness:0,
                smssentkiosken: 0,
                smssentkioskspanish:0,
                theft:_theft,
                timestamp:0,
                total:_totalcheckin,
                void:_void,
                _return:_returning,
                guid:finalguid
              })


              final.push({
                BusinessPath:"contact",
                dd:arr.dd,
                guid:busintotal
              })    

            

              Object.keys(busintotal).map((key:any)=>{
        
                busintotal[key].map((v:any)=>{
                    ttsms+=v._totalsmsb
                    ttmms+=v._totoalmmsb
                  })
             
              });

                final.push({
                  BusinessPath:"other", // for other messages who`s stoped messages frm texn
                  LastUpdatedDate:'',
                  NextUpdateDate:'',
                  SitePath:"",
                  msgcode:'',
                  cash4cars:0,
                  dd:``,
                  month:'',
                  year:'',
                  plan:plan,
                  firstresponder:0,
                  used,
                  new:  0,
                  optout:  0,
                  recheck : 0,
                  smssentjustIn:0,
                  smssent:_totalsms,
                  mmssent:_totoalmms,
                  smssentBusiness:ttsms,
                  mmssentBusiness:ttmms,
                  smssentkiosken: 0,
                  smssentkioskspanish:0,
                  theft:0,
                  timestamp:0,
                  total:0,
                  void:0,
                  _return:0,
                  guid:finalguid
                })        

             
           
            used +=(ttsms -used)
            final.push({
                BusinessPath:"",
                LastUpdatedDate:'',
                NextUpdateDate:'',
                msgcode:'',
                SitePath:"",
                cash4cars:0,
                dd:``,
                month:'',
                year:'',
                plan:plan,
                firstresponder:0,
                new: 0,
                optout:  0,
                used,
                recheck : 0,
                smssentjustIn:0,
                smssent:0,
                mmssent:0,
                smssentBusiness:0,
                mmssentBusiness:0,
                smssentkiosken: 0,
                smssentkioskspanish:0,
                theft:0,
                timestamp:0,
                total:0,
                void:0,
                _return:0,
                guid:finalguid
              })

              ttsms = 0
              ttmms = 0 
              busintotal =[]                        
                _new = 0;
                _returning=0;
                _optout=0;
                _void=0;
                _recheck=0;
                _firstreponder = 0;
                _theft= 0;
                _totalsms=0;
                _totoalmms=0;                
                _totalcheckin = 0;

                _totalsmsb=0;
                _totoalmmsb=0;
                 used= 0
        }



      })
      

    const finall =await  final.filter(async(xy:any)=>{
        if(xy.BusinessPath=='contact'){
            const arr:any = []
            Object.keys(xy.guid).map((cc:any)=>{
            arr.push(cc)
            })

            await getguidContactsSupportedCount({guid:arr})
              .then(async(result:any) => {  
                try {                                
                    console.log(result);
                    xy.contacts =result.data
                    } catch (error) {
                      xy.contacts =0
                    }   
              })
            
        }
     return xy
      })
           
      setTimeout(() => {
      setdatesarray(finall)
      console.log(finall,'final');
      
      setDoc(doc(collection(firebase, "checkInCountReportcache"), groupname+selectedmonth), {final:finall}  );       
      }, 2000);       
    }else{
    setdatesarray(datesarrayinit)
    }
  
      
      
  
  
  }
  

    const getgroups=(async()=>{
       const docs = await getDocs(collection(firebase, "BusinessGroups"));
      
       let grp:any =[]
       if((user!==undefined  && user!==null) && (user.accountData.permissions!==undefined) && ( user.accountData.permissions.role!==undefined )){

        if(user.accountData.permissions.groups!=undefined){
          user.accountData.permissions.groups.map((x:any)=>{
            grp.push(x.value)
          })
       

        }

       
       if(!docs.empty){   
        const arr :any=[]
        docs.docs.map((val:any)=>{
          if((grp.includes(val.id) && val.data().status=='active') || user.accountData.permissions.role=='admin' ){
            const v = val.data();

             v.id = val.id;
            arr.push(v)   
          }
          
        })
          arr.sort((a:any,b:any) => a.name.localeCompare(b.name, undefined, { sensitivity: 'base' }));
        console.log(arr);
        
        setgroups(arr);
       }
      }
    })

    const GroupChange=(v:any)=>{


     const grp = groups.filter((vv:any)=>(vv.id==v))
    if(grp.length>0){    
        setgroup(grp[0])
        let coun =0
        console.log(grp[0]);
        if(grp[0].business!==undefined){
          const boolValues = Object.values(grp[0]).filter(value => typeof value === "boolean" && value==true);
          if(boolValues.length!=14){
          if(grp[0].recheck==undefined || grp[0].recheck==true){ coun +=1 } 
          if(grp[0].firstresponder===undefined || grp[0].firstresponder==true){  coun +=1 } 
          if(grp[0].theft==undefined || grp[0].theft==true){coun +=1 }


          topheadercol.third = (grp[0].totalmmsandsms==undefined || grp[0].totalmmsandsms==true)?  0 : 1    
          topheadercol.first =(boolValues.length-1)-coun
          if(topheadercol.third==1){
            topheadercol.first = topheadercol.first+1
          }
          topheadercol.second =coun

          settopheadercol(topheadercol)
          }

        }else{
          settopheadercol({
            first:10,
            second:3,
            third:0,
           })
        }

    }

        setgroupname(v)  
    }

    const getreportbygroup=async()=>{
      
        if(groupname==''){
            alert('Please select group name.')
        }else{
            setdatesarray([])
            setloading(true)

        // const cachedata = (await getDoc( doc(collection(firebase, "checkInCountReportcache"), groupname+selectedmonth)))             
        // if(!cachedata.exists()){  
          
          
        const addMessage = httpsCallable(functions, 'getcheckinreportbygroup',{ timeout :500000});
        await addMessage({groupname,selectedmonth})
        .then(async(result:any) => {      
   
        if(result.data && result.data[groupname]!==undefined){
            setdatesarrayinit(result.data[groupname])
            setTimeout(() => {

               groupBy('month',result.data[groupname])

            }, 1000);       
        }         
                setloading(false)
        }).catch((e)=>{
          console.log(e,'erorrr');
          
            setloading(false)
        });

      // }else{       
      //   setdatesarray(cachedata.data().final)   
      //   setloading(false)      
      // }
    }

    }

    const today = new Date();

    const lastupdate = new Date();
    lastupdate.setDate(today.getDate() -3);

    const lastdatestring = `${(lastupdate.getMonth()+1)>9? (lastupdate.getMonth()+1) : '0'+(lastupdate.getMonth()+1) }/${lastupdate.getDate()>9 ? lastupdate.getDate() : '0'+lastupdate.getDate()}/${lastupdate.getFullYear()}`
    // Add 1 day to today's date
    const tomorrow = new Date();
    tomorrow.setDate(today.getDate() + 1);

    const csvDownloads=()=>{
          
    const table = document.getElementById("myTable");
    const workbook = XLSX.utils.table_to_book(table, { sheet: "Sheet1" });
    XLSX.writeFile(workbook, "table.xlsx");
        
        }





    const monthname =(dateInput:any)=>{
      // Split the input into year and month
    let [year, month] = dateInput.split('/').map(Number);

    // Convert month number to month name
    let monthName = new Date(year, month - 1).toLocaleString('en-US', { month: 'short' });

// Combine year, month, and name
    const monthx =month<10 ? `0${month}` : month
    return  `${monthx} / ${year} (${monthName})`;

    }

    function formatToIndianNumberingSystem(number:any) {
      if(number>0){
      return new Intl.NumberFormat('en-US').format(number);
      }else{
        return 0
      }
  }

  const showcolumn=(col:string)=>{
    try {

if(group[col]===undefined || group[col]===true){
    return 'table-cell'
}else{
  
  return 'none';
}
      
} catch (error) {
  console.log(error);
  return 'none';
}

  }

const final:any = useSelector(state=>state);
let rowspanbusienssv:any= [];
const rowspanHandle=(x:any)=>{
  
 let re =0

  if(final.BusinessesReducer.Businesses[0]!==undefined && final.BusinessesReducer.Businesses!==null){

    final.BusinessesReducer.Businesses.map((b:any)=>{

      if(b.path==x.BusinessPath && !rowspanbusienssv.includes(b.path+x.dd)){
       re =  b.sites.length
       console.log(b.path+x.dd,re);
       
       rowspanbusienssv.push(b.path+x.dd)
      }

    });
  }
  return re

}


const formatToIndianNumberingSystemrow=(xy:any)=>{
   
  const finalsms =  (xy.smssentBusiness-xy.smssent)+((xy.mmssentBusiness*2)-(xy.mmssent*2))
  return formatToIndianNumberingSystem(finalsms)
}





  
    return(
        <div className="containerx">

        <div>
        <select className='selectInput' onChange={(e)=>GroupChange(e.target.value)}><option value={''}>select Group</option> {groups.map((val:any,i:any)=>(<option key={i} value={val.id}>{val.name}</option>))}</select>
        <select className='selectInput'  id="last-12-monthsx" onChange={(e)=>setselectedmonth(e.target.value)} name="last-12-months"></select>
<Button className='buttonInput'  loading={loading} onClick={()=>getreportbygroup()} ><Icon icon='search'  color='#fff' size={15}/></Button>
       
        </div>


<div>
{datesarray.length>0 && !loading? <div>
    <Button className='selectInput' style={{color:'white',marginBottom:''}} onClick={()=>csvDownloads()}>CSV Download</Button>
    
    <HTMLTable id="myTable"  border={1}>
      <tr>
      <th colSpan={topheadercol.first}><p><b>Last Updated: {lastdatestring}</b></p></th>
     {topheadercol.second!=0? <th style={{textAlign:'center'}} colSpan={topheadercol.second} >Not Counted</th>:''}
     {topheadercol.third==0 && showcolumn('totalmmsandsms')!='none'? <th colSpan={2}>1 MMS = 2 SMS</th>:''}
      </tr>
    <tr>
        <th style={{display:showcolumn('date')}}>Date <br/> MM/YYYY (MON)</th>
        <th style={{display:showcolumn('business')}}>Business</th>
        <th style={{display:showcolumn('site')}}>Site City</th>
        <th style={{display:showcolumn('tf')}}>{group.id=='Roberts-Parts-U-Pick' || group.id=='Locos-Gringos-Pick-N-Pull' ? '10 DLC' :'TF#'}</th>
        <th style={{display:showcolumn('totalcheckins')}}>Total Checkins (new+returning+opt-out+void)</th>
        <th style={{display:showcolumn('totalmsg')}}>Total Message Credits Used (SMS / MMS)</th>
        <th style={{display:showcolumn('new')}}>New</th>
        <th style={{display:showcolumn('returning')}}>Returning</th>
        <th style={{display:showcolumn('optout')}}>Opt-Out</th>
        <th style={{display:showcolumn('void')}}>Void</th>
        <th style={{display:showcolumn('recheck')}}>Recheck</th>        
        {/* <th>Cash For Cars</th> */}
        <th style={{display:showcolumn('firstresponder')}}>First Responder</th>
        <th style={{display:showcolumn('theft')}}>Theft</th>
    
    
        <th style={{display:showcolumn('totalmmsandsms')}}> Total SMS / MMS Credits Sent</th>
        {/* <th style={{display:showcolumn('totalmmsandsms')}}> Contacts</th> */}

      </tr>
      <tbody className="tbodyclass">
    
      {datesarray.length>0 ? datesarray.map((xy:any,inx:any)=>(
    xy.BusinessPath!=''  && xy.BusinessPath!='xxxxxx' && xy.BusinessPath!='other' && xy.BusinessPath!='contact' ?  <tr>
        <td style={{display:showcolumn('date')}}>{monthname(xy.dd)}</td>
        <td style={{display:showcolumn('business')}}>{ xy.BusinessPath.toUpperCase()}</td>
        <td style={{display:showcolumn('site')}}>{xy.SitePath.toUpperCase()}</td>
        <td style={{display:showcolumn('tf')}}>{xy.msgcode}</td>
        <td style={{display:showcolumn('totalcheckins')}}>{formatToIndianNumberingSystem(xy.total)}</td>    
        <td style={{display:showcolumn('totalmsg')}}>{formatToIndianNumberingSystem(xy.smssent+(xy.mmssent*2))}</td>
        <td style={{display:showcolumn('new')}}>{formatToIndianNumberingSystem(xy.new)}</td>
        <td style={{display:showcolumn('returning')}}>{formatToIndianNumberingSystem(xy._return)}</td>
        <td style={{display:showcolumn('optout')}}>{formatToIndianNumberingSystem(xy.optout)} </td>
        <td style={{display:showcolumn('void')}}>{formatToIndianNumberingSystem(xy.void)}</td>
        <td style={{display:showcolumn('recheck')}}>{formatToIndianNumberingSystem(xy.recheck)}</td>
        {/* <td>{xy.cash4cars}</td> */}
        <td style={{display:showcolumn('firstresponder')}}>{formatToIndianNumberingSystem(xy.firstresponder)}</td>
        <td style={{display:showcolumn('theft')}}>{formatToIndianNumberingSystem(xy.theft)}</td>
      
        <td style={{display:showcolumn('totalmmsandsms')}}>{formatToIndianNumberingSystem(xy.smssent)} / {formatToIndianNumberingSystem(xy.mmssent)}</td>
        {/* <td style={{display:showcolumn('theft')}}>{formatToIndianNumberingSystem(xy.contact)}</td> */}

       {/* {rowspanHandle(xy)>0? <td rowSpan={rowspanHandlegetlength(xy) } colSpan={1} style={{display:showcolumn('totalmmsandsms')}}>

       {formatToIndianNumberingSystemrow(xy)}</td>:''} */}


</tr>: xy.BusinessPath=='other' ?

        <tr style={{background:'lightgrey',border:'none'}} >  
        <td   colSpan={4}><H6><b>Other Messages:</b></H6></td>      
        <td style={{display:showcolumn('totalcheckins')}}></td>
        <td style={{display:showcolumn('totalmsg')}}><H6>{formatToIndianNumberingSystemrow(xy)}</H6></td>
        <td style={{display:showcolumn('new')}}></td>
        <td style={{display:showcolumn('returning')}}></td>
        <td style={{display:showcolumn('optout')}}></td>
        <td style={{display:showcolumn('void')}}></td>
        <td  style={{display:showcolumn('recheck')}}></td>
        <td style={{display:showcolumn('firstresponder')}}></td>
        <td style={{display:showcolumn('theft')}}></td>
        <td  style={{display:showcolumn('totalmmsandsms')}}></td>
        {/* <td  style={{display:showcolumn('totalmmsandsms')}}></td> */}
        </tr> 

: xy.BusinessPath=='xxxxxx' ?

        <tr style={{background:'lightblue',border:'none'}} >  
        <td   colSpan={4}><H6><b>Checkin Totals:</b></H6></td>      
        <td style={{display:showcolumn('totalcheckins')}}><H6>{formatToIndianNumberingSystem(parseInt(xy.total))}</H6></td>
        <td style={{display:showcolumn('totalmsg')}}><H6>{formatToIndianNumberingSystem(xy.smssent+(xy.mmssent*2))}</H6></td>
        <td style={{display:showcolumn('new')}}><H6>{formatToIndianNumberingSystem(parseInt(xy.new))}</H6></td>
        <td style={{display:showcolumn('returning')}}><H6>{formatToIndianNumberingSystem(parseInt(xy._return))}</H6></td>
        <td style={{display:showcolumn('optout')}}><H6>{formatToIndianNumberingSystem(parseInt(xy.optout))}</H6></td>
        <td style={{display:showcolumn('void')}}><H6>{formatToIndianNumberingSystem(parseInt(xy.void))}</H6></td>
        <td  style={{display:showcolumn('recheck')}}><H6>{formatToIndianNumberingSystem(parseInt(xy.recheck))}</H6></td>
        <td style={{border:'none',display:showcolumn('firstresponder')}}><H6>{formatToIndianNumberingSystem(parseInt(xy.firstresponder))}</H6></td>
        <td style={{display:showcolumn('theft')}}><H6>{formatToIndianNumberingSystem(parseInt(xy.theft))}</H6></td>
        <td  style={{display:showcolumn('totalmmsandsms')}}><H6>{formatToIndianNumberingSystem(xy.smssent)} / {formatToIndianNumberingSystem(xy.mmssent)}</H6></td>
        {/* <td  style={{display:showcolumn('totalmmsandsms')}}></td> */}
        </tr> 

: xy.BusinessPath=='contact' ?

        <tr style={{background:'bisque',border:'none'}} >  
        <td   colSpan={4}><H6><b>Contact Supports Total:</b></H6></td>      
        <td style={{display:showcolumn('totalcheckins')}}><H6>{xy.contacts}</H6></td>
        <td style={{display:showcolumn('totalmsg')}}></td>
        <td style={{display:showcolumn('new')}}></td>
        <td style={{display:showcolumn('returning')}}></td>
        <td style={{display:showcolumn('optout')}}></td>
        <td style={{display:showcolumn('void')}}></td>
        <td  style={{display:showcolumn('recheck')}}></td>
        <td style={{display:showcolumn('firstresponder')}}></td>
        <td style={{display:showcolumn('theft')}}></td>
        <td  style={{display:showcolumn('totalmmsandsms')}}></td>
        {/* <td  style={{display:showcolumn('totalmmsandsms')}}></td> */}
        </tr> 

:  
        <tr style={{background:'lightsteelblue',border:'none'}} >  
        <td  style={{border:'none'}}><H6><b>Message Totals:</b></H6></td>     
        <td  style={{border:'none',textAlign:'right'}}><H6>Monthly Plan:</H6></td>
        <td style={{border:'none'}}><H6>{formatToIndianNumberingSystem(parseInt(xy.plan))}</H6></td>
        <td colSpan={2}  style={{border:'none' ,textAlign:'right'}}><H6>Message Credits Used :</H6></td>
        <td colSpan={1}  style={{border:'none'}}><H6>{formatToIndianNumberingSystem(xy.used)}</H6></td>
        <td colSpan={2}  style={{border:'none'}}><H6>{xy.used>xy.plan ? 'Message Credits over Monthly Plan' : 'Remaining Message Credits'}: </H6></td>
        <td  style={{border:'none',textAlign:'right'}}><H6>{formatToIndianNumberingSystem(Math.abs(xy.plan-xy.used))}</H6></td>
        <td  style={{background:'none',border:'none',display:showcolumn('recheck')}}></td>
        <td  style={{background:'none',border:'none',display:showcolumn('firstresponder')}}></td>
        <td   style={{background:'none',border:'none',display:showcolumn('theft')}}></td>
        <td  style={{background:'none',border:'none',display:showcolumn('totalmmsandsms')}}></td>
        {/* <td  style={{background:'none',border:'none',display:showcolumn('totalmmsandsms')}}></td> */}
        <td></td>
        </tr> 
  
    
   ) ):''}
      </tbody>
      </HTMLTable>
      </div>
    : loading ? <Spinner intent={'primary'} size={30}/> :''}
    <br/><br/><br/>
       


    </div>    </div>
    )

}


export default Summaryreportbygroup;
