

import {addArchivebusiness, addbusiness, addpointsytembusiness, fetchingBool, fetchinglen}  from '../actions/businesses/PushBusinessesReducer';
import { BusinessesCollection, firebase, urls } from '../global/firebase';
import { collection, getDocs, query ,orderBy} from 'firebase/firestore';
import store from './store/store';
import { substractRead } from '../global/documentreadcount';
// import { orderBy } from 'lodash';

 const initApp=async()=>{ 


console.log('init hit');
try {
  await GetBusiness();
 } catch (error) {
   
 }

try {
const userState = localStorage.getItem('_auth_state');
if(userState!==undefined && userState!==null){
  const user =   JSON.parse(userState).accountData;
    const sitesx =user.permissions.sites!==undefined && user.permissions.sites.length>0? filtersites(user.permissions.sites) : []
    const q = query(BusinessesCollection,orderBy("path"));
    const doc =await getDocs(q)
    const b:any =[]
    const archivebusienss:any =[]
    if(!doc.empty){
    substractRead(doc.size)
    doc.forEach(async(r)=>{
      if(user.permissions.businesses.includes(r.id) || user.permissions.businesses.length<=0 && r.data().isArchive!==1 && r.data().isArchive!=='1'){
     const data:any= r.data()  
     data.businessId =  r.id
     const qs = query(collection(firebase, 'businesses/'+r.id+'/sites'));
     const docs =await getDocs(qs)
     if(!docs.empty){
      const arrSite:any =[]  
      substractRead(docs.size)
      docs.forEach((site)=>{

        if(sitesx.includes(site.id) || sitesx.length<=0){
          const sitedata= site.data()
          sitedata.siteId =site.id
          arrSite.push(sitedata)
        }

      })
      data.sites=arrSite
    }
     b.push(data);
    }else if(r.data().isArchive!==undefined && (r.data().isArchive===1 || r.data().isArchive==='1')){
      const data:any= r.data()   
      const qs = query(collection(firebase, 'businesses/'+r.id+'/sites'));
      const docs =await getDocs(qs)
      if(!docs.empty){
       const arrSite:any =[] 
       substractRead(docs.size) 
       docs.forEach((site)=>{
         if(sitesx.includes(site.id) || sitesx.length<=0){
          const sitedata= site.data()
          sitedata.siteId =site.id
          arrSite.push(sitedata)
         }
       })
       data.sites=arrSite
     }
     archivebusienss.push(data);
    }
    });

   store.dispatch(addbusiness(b))
   store.dispatch(addArchivebusiness(archivebusienss))

   store.dispatch(fetchinglen(doc.size))  
      }
      

    }

   
  
    store.dispatch(fetchingBool(false)) 
    

    
} catch (error) {
    window.location.href=''
}




  }



  const GetBusiness=async()=>{  
   const yesterday = new Date(); 
   try {
    yesterday.setDate(yesterday.getDate() - 1);    
    localStorage.removeItem('pointsBuisnesses'+yesterday.getDay());
   } catch (error) {
    console.log(error);
    
   } 
   try {
    const day =new Date().getDay()
    const pointsBuisnesses = localStorage.getItem('pointsBuisnesses'+day);
    if(pointsBuisnesses!==undefined && pointsBuisnesses!==null){
    const arre =   JSON.parse(pointsBuisnesses);
    console.log('p1s5 businesses from', arre);
    store.dispatch(addpointsytembusiness(arre))

    }else{
    const xhr = new XMLHttpRequest();
    xhr.open('POST', urls.pointsystem+'p1s5e6_get_businesses', true);
    xhr.setRequestHeader('Content-type', 'application/json');
    xhr.onload = async (x:any) =>{
        // do something to response
     
    const business = await JSON.parse(x.target.response)
    
    const arr:any=  [];
    Object.keys(business).forEach(async(k,i) => {      
      if(business[k]!==undefined && business[k].name!==undefined){
  
      business[k].order = business[k].is_disabled!==undefined && business[k].is_disabled===1 ? 3 : 1;
      arr.push(business[k])
      }
    })  
    localStorage.setItem('pointsBuisnesses'+day,JSON.stringify(arr));
    store.dispatch(addpointsytembusiness(arr))
  
    }
    xhr.send();
  }


   } catch (error) {
    console.log(error);
    
   }
  }
export default initApp    


  const filtersites=(sites:any)=>{
   return sites.filter((r:any)=>{
if(r!==undefined && r!==null && r!=='' && r!==' '){
return true
}else{
return false
}
   })
  }
