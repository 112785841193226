import React, { useEffect, useState }  from 'react';
import { Button,  ButtonGroup,  Dialog, DialogBody,  H2,  H5,  Icon, InputGroup, Switch } from '@blueprintjs/core';
import '../../assets/css/checkins.css'
import { useSelector } from 'react-redux';
import { CheckinsTable } from './components/CheckinsTable';
import { Users } from '../../interfaces/users';
import { addDoc, and, collection, doc,  endAt,  endBefore, getDoc, getDocs, limit, limitToLast, onSnapshot, or, orderBy, query, setDoc, startAfter,  startAt,  updateDoc,  where } from 'firebase/firestore';
import { firebase, urls } from '../../global/firebase';
import { useAuthUser, useIsAuthenticated } from 'react-auth-kit';
import { ActiveCheckIns } from './components/ActiveCheckIns';
import Swal from 'sweetalert2'
import { substractRead } from '../../global/documentreadcount';
const audioAppleElement = new Audio(require("../../assets/audio/Apple.mp3"));
let unsubscribev:any =()=>console.log();
export const CheckInHistory = ()=>{
   const object ={
        bannedCusotmerTableRowBgColor:'#000',
        bannedCusotmerTableRowFontColor:'#fff',
        buttonColor:'linear-gradient(94.46deg,#0d47f0,#3f79cb)',
        leftSideBgColor:'',
        leftSideTagColor:'',
        mainBackgroundColor:'',
        tableBackgroundColor:'',
        tableBorderColor:'',
        tableFontColor:'',
        buttonFontColor:'#fff',
        tableHeaderColor:''   
   }

   let TotalNewSubscriberlocal1=0
   let TotalOptOutslocal2=0
   let TotalReCheckinslocal3=0
   let TotalCheckins4 =0
    // const synth = window.speechSynthesis;
    const final:any = useSelector(state=>state);
    const [businesses,setbusiness]=useState([] as any);
    const [showAllSites,setshowAllSites]=useState(true)
    const [volume,setVolume]=useState(0.0 as any);
    const [business,setbusines]=useState([] as any);
    const [sites,setsites]=useState([] as any);
    const [fontSize,setfontSize]=useState(12)
    const [siteId,setsiteId]=useState('');
    const [businessId,setbusinessId]=useState('');
    const [loading,setloading]=useState(false);
    const [loaded,setloaded]=useState(false)
    const [users,setusers]=useState([] as any)
    const [checkIns,setcheckIns]=useState([] as any)
    const [tableOptions,settableOptions]=useState([] as any)
    const [previewOptions,setpreviewOptions]=useState([] as any)
    const [openSetting,setopenSetting]=useState(false)
    const auth:any = useAuthUser();
    const [limits,setlimits]=useState(35)
    const [startAfterId,setstartAfterId]=useState(null)
    const [page,setpage]=useState(1)
    const isAuthenticated = useIsAuthenticated(); 
    const [beforeAfterId,setbeforeAfterId]=useState(null)
    const [searchQuery,setsearchQuery]=useState(['mobile',null] as any)
    const [searchDate,setsearchDate]=useState(['timestamp',null] as any)
    const [cnkrvCountsType,setcnkrvCountsType]=useState(1);
    const [TotalCheckins,setTotalCheckins]=useState(0)
    const [TotalNewSubscriber,setTotalNewSubscriber]=useState(0)
    const [TotalOptOuts,setTotalOptOuts]=useState(0)
    const [TotalReCheckins,setTotalReCheckins]=useState(0)
    const [TotalVoids,setTotalVoids]=useState(0)
    const [CheckInCss,SetCheckInCss]=useState(object)
    const [ActiveCheckinsxx,setActiveCheckinsxx]=useState([] as any)
    const [live,setlive]=useState(localStorage.getItem('S_check_ins_v1_mode')!==undefined && localStorage.getItem('S_check_ins_v1_mode')==='reporting' && localStorage.getItem('S_check_ins_v1_mode')!==null ? false : true)
    const [Subscribe,setSubscribe]=useState([] as any)
    const [darkMode,setdarkMode]=useState(false)
    const [IssearchingEnabledByName,setIssearchingEnabledByName]=useState(false)
    const [IssearchingEnabledByMobile,setIssearchingEnabledByMobile]=useState(false)
    const [IssearchingEnabledByDate,setIssearchingEnabledByDate]=useState(false)
    const [modeinfo,setmodeinfo]=useState('')


    let countwww= 0
    const defaultTableOptions:any = [
        { enabled: true, name: "name" , namex:'Name' ,order:1},
        { enabled: true, name: "mobile" ,namex:'Phone Number' ,order:2},
        { enabled: true, name: "time",namex:'Time' ,order:3},
        { enabled: true, name: "mugshot" ,namex:'Picture',order:4},
        { enabled: true, name: "kind" ,namex:'Check-In Type' ,order:5},
        { enabled: true, name: "date",namex:'Date' ,order:6},
        { enabled: true, name: "site",namex:'Location' ,order:7},
        { enabled: false, name: "timestamp",namex:'Timestamp' ,order:8},
        { enabled: false, name: "dateofbirth",namex:'Birth Date' ,order:9},
        // { enabled: false, name: "covidquestions",order:10 },
        // { enabled: false, name: "supercard" ,order:10},
      ];

      const defaultOptionsLeftPanel:any = [
        { enabled: true, name: "mugshot" },
        { enabled: true, name: "signature" },
        { enabled: true, name: "name" },
        { enabled: true, name: "coupons" },
        { enabled: true, name: "vehicles" },
        { enabled: true, name: "language" },
        { enabled: true, name: "mobile" },
        { enabled: true, name: "time" },
        { enabled: false, name: "dateofbirth" },
        { enabled: false, name: "email" },
        { enabled: false, name: "site" },
        // { enabled: false, name: "supercard" },
        { enabled: true, name: "waiver" },
        { enabled: false, name: "zip" },
      ];


    useEffect(()=>{ 


        
          if(businesses[0]===undefined){
            setloading(true) 
          if(final.BusinessesReducer.Businesses[0]!==undefined && final.BusinessesReducer.Businesses!==null){

              try {
              
              const ReturnBusiness:any = getBusinessSiteIdsFromUrl(final.BusinessesReducer.Businesses)
              setbusiness(final.BusinessesReducer.Businesses)
              setbusines(ReturnBusiness)
              setsites(ReturnBusiness.sites) 
                try {
                    if(auth().accountData.permissions.role!=='admin'){
                                const  rw = ReturnBusiness.sites[0];       
                                    openSwalAlert(`${ReturnBusiness.path}__${rw.path}`)
                                            
                            }
                                
                } catch (error) {
                    console.log(error);
                    
                }

             

              setbusinessId(ReturnBusiness.businessId)
              if(ReturnBusiness.checkInsOptions!==undefined && (ReturnBusiness.colormode!==undefined && ReturnBusiness.colormode===true)){
                ReturnBusiness.checkInsOptions.buttonFontColor='#fff';
                SetCheckInCss(ReturnBusiness.checkInsOptions)
              }
              const el:any =document.querySelector('.bp5-table-quadrant-scroll-container')  
              if(ReturnBusiness.darkmode!==undefined && ReturnBusiness.darkmode===true){
                setdarkMode(true)
                OnchangeDarkMode()              
                if(el!==undefined && el!==null){
                el.style.background='rgb(92, 112, 128)'
                }

              }else{
                setdarkMode(false)
                if(el!==undefined && el!==null){
                    el.style.background=CheckInCss.tableBackgroundColor
                    }
              } 
              
              NewkioskupdateAlert(final)
                      
          } catch (error) {
                 console.log(error,'useeffect error');      
          }
          }           
      }  

      if(checkIns[0]===undefined && loaded==false){ 

               
            ResetFilter()
      if(isAuthenticated() && tableOptions.length<1){
        if(auth().siteOptions!==undefined && auth().siteOptions.checkIns!==undefined && auth().siteOptions.checkIns.tableOptions!==undefined){ 
                proccessTableOptions(auth().siteOptions.checkIns.tableOptions)                   
            
          }else{        
            settableOptions(defaultTableOptions)
          }

          if(auth().siteOptions!==undefined && auth().siteOptions.checkIns!==undefined && auth().siteOptions.checkIns.previewOptions!==undefined){                        
            setpreviewOptions(auth().siteOptions.checkIns.previewOptions)
          }else{        
            setpreviewOptions(defaultOptionsLeftPanel)
          }    
  
    } 

   }
  

   const val = localStorage.getItem('volume')!==undefined && localStorage.getItem('volume')!==null ? localStorage.getItem('volume') : 0.0;    
   if(localStorage.getItem('volume')===undefined || localStorage.getItem('volume')===null){
     localStorage.setItem('volume','0.0');
   }
   setVolume(val)

  },[final,checkIns])


  const NewkioskupdateAlert=async(final:any)=>{
try {

    // set new kiosk update alert massage
    if(window.location.hash.includes('*****')){
        console.log('*******set successfully********');
          const clearedData = await getDocs(query(collection(firebase,'CacheClearLog')))
            
              final.BusinessesReducer.Businesses.map((res:any)=>{
                res.sites.map((sit:any)=>{
                let refresh = true;                
                    if(!clearedData.empty){
                        clearedData.docs.map((ro:any)=>{
                                if((ro.data().path).includes(`${res.path}/${sit.path}`)){
                                    refresh = false
                                }
                        })
                    }

            if(refresh){
                console.log(res.path+'__'+sit.path);
                
              setDoc(doc(firebase, "kioskUpdatesNote",res.path+'__'+sit.path), {
                business:res.path,
                kiosk:sit.path,
                note:`
<p>Dear Team,</p><br/>

<p>We are excited to announce the launch of our upgraded customer side Kiosk software.  To ensure smooth operation of the customer side Kiosk software, please follow the following steps 1 time now and then at least once a month ongoing:</p><br/>

<p>1) Reboot the Kiosk device (tablet or computer).</p><br/>

<p>2) Once the Keypad is back up, enter '0000' and then clear cache on all Kiosk in use at your location.</p><br/><br/>

 

<p>Thank you,</p><br/>

<p>Team Texn Rewards!</p><br/>

<p>info@texnrewards.com</p>
                `,
                readBy:{
                    owner:false,
                    staff:false,
                },
                executeBy:{
                    owner:false,
                    staff:false,
                },
                executeDate:{
                    owner:null,
                    staff:null,
                },
                timestamp:new Date()
    
            })

        }
        });


        });
 

    }
        
} catch (error) {
    console.log(error);
    
}
  }

  const openSwalAlert=async(businesssitename:any)=>{
try {
    const docd = await getDoc(doc(firebase,'kioskUpdatesNote',businesssitename))
    if(docd.exists()){
     if(docd.data()['executeBy'][auth().accountData.permissions.role]===false){
        const executeBy=docd.data()['executeBy'];
        const note=docd.data()['note'];
        const executeDate=docd.data()['executeDate'];
        const readBy=docd.data()['readBy'];
    Swal.fire({
        position: "top-end",
        title: 'New Software Upgrade and Cache Clearing Instructions',
        html: note,
        icon: "question",
        showConfirmButton:true,
        showCancelButton:true,
        
      }).then((result) => {

        if (result.isConfirmed) {
            executeBy[auth().accountData.permissions.role] = true;
            executeDate[auth().accountData.permissions.role] = new Date();
            readBy[auth().accountData.permissions.role] = true; 
            updateDoc(doc(firebase,'kioskUpdatesNote',businesssitename),{
                executeBy,
                executeDate,
                readBy
            })
        //   Swal.fire("I hope you cleared cache on your user kiosk screen", "", "success");
        } else if (result.isDismissed) {
            readBy[auth().accountData.permissions.role] = true; 
            updateDoc(doc(firebase,'kioskUpdatesNote',businesssitename),{
                readBy
            })
        console.log('cancled');
        
        }
      });
    }
}

    } catch (error) {
    console.log(error);
    
    }
}


  const proccessTableOptions=(options:any)=>{
try {
   const row =  options.filter((x:any,i:any)=>{

                x.namex = x.name
                x.order=i+1
                if(x.name!=='covidquestions' && x.name!=='supercard'){
                return x
                }else{
                    return false;
                }
    });

    settableOptions(row)
} catch (error) {
    settableOptions(defaultTableOptions)
}
  }

  const getBusinessSiteIdsFromUrl=(data:any)=>{


 const final = data.filter((x:any)=>(x.sites!==undefined && x.sites.length>0))
   

    try {
        if(final[0].sites.length>1){
            setshowAllSites(true)
            setsiteId('')  
        }else{
         setshowAllSites(false)
         setsiteId(final[0].sites[0].siteId)    
        }  
    } catch (error) {
    console.log(error);
    }


    return final[0]   


  }




  const OnchangeDarkMode=  ()=>{
    SetCheckInCss({
        bannedCusotmerTableRowBgColor:'rgb(169 41 11)',
        bannedCusotmerTableRowFontColor:'#fff',
        buttonColor:'rgb(155, 155, 155)',
        leftSideBgColor:'#000000',
        leftSideTagColor:'',
        mainBackgroundColor:'#313030',
        tableBackgroundColor:'#000000',
        tableBorderColor:'#fff',
        tableFontColor:'#fff',  
        buttonFontColor:'#fff',
        tableHeaderColor:'rgb(92, 112, 128)'      
   })
  }
    const getCheckIns=async(DirectionDoc:any,Direction:any,search:any,businessId:string,siteId:string,limitPage:number,sitesr:any)=>{
    console.log('get checkins function call');
            try {
        TotalNewSubscriberlocal1=0;
        TotalOptOutslocal2=0;
        TotalReCheckinslocal3=0;
        TotalCheckins4=0;
        await UpdateCounts(cnkrvCountsType,businessId,siteId);
        setTimeout(async() => {  
         
        let quer:any=[];
        const date = new Date();
        let count =0;
        const month = (date.getMonth()+1)<10 ? '0'+(date.getMonth()+1) :  date.getMonth()+1;
        const ddayy = date.getDate()<10 ? '0'+(date.getDate()) :  date.getDate();
        const fs17concat=  businessId+'_'+date.getFullYear()+'_'+month+'_'+ddayy
        let searchQuery = or(where('timestamp','!=',null))
        const audioElement = new Audio(require("../../assets/audio/DangerWarning.mp3"));
        audioAppleElement.volume=1
        const docSnap =DirectionDoc!==null ?  DirectionDoc : 'Null';              
        let stsrtAt =Direction==='previous' ? endBefore(docSnap) :  startAfter(docSnap);
        const limitTo=Direction==='previous' ? limitToLast(limitPage) : limit(limitPage)   
        // let endat =endAt(null)
        let ordercol = 'timestamp';
        let orderbyqury = orderBy(ordercol,'desc')
        if(search[0]!==undefined && search[1]!==undefined && search[0]!==null && search[1]!==null){
            // if(search[0]==='timestamp'){
                const date = new Date(search[1])
                ordercol=search[0]
                date.setDate(date.getDate() + 1);
                const searchValue =search[0]=='timestamp' ? date :  search[1];
                const oprator = search[0]=='timestamp' ? '<=' : '==';
                if(ordercol==='name.displayName'){
                  searchQuery= or(where('name.firstname',oprator,searchValue),where('name.lastname',oprator,searchValue),where('name.displayName',oprator,searchValue))
                }else{
                  searchQuery= or(where(search[0],oprator,searchValue))  
                }
                // console.log(searchQuery); 
            // }else{
            //     searchQuery=  where(search[0],'!=',null)
            //     ordercol=search[0]
            //     stsrtAt=startAt(search[1])
            //     endat= endAt(search[1]+'\uf8ff')
            //     orderbyqury=orderBy(ordercol)
            // }
            if(ordercol==='name.displayName'){
              setIssearchingEnabledByName(true);
            }else if(ordercol==='mobile'){
                setIssearchingEnabledByMobile(true)
            }else if(ordercol==='timestamp'){
                setIssearchingEnabledByDate(true)
            }else{
                setIssearchingEnabledByName(false); 
                setIssearchingEnabledByMobile(false)
                setIssearchingEnabledByDate(false)
            }
            
        }


        if(siteId!==null && siteId!==''){
            quer =query(collection(firebase,'checkIns'),and(and(where('businessId','==',businessId)) ,
             and(where('siteId','==',siteId)),searchQuery),orderbyqury,stsrtAt,limitTo)

            
        }else{   

            quer =query(collection(firebase,'checkIns'),
             and(and(where('businessId','==',businessId)),searchQuery),orderbyqury,stsrtAt,limitTo)
        }

 
        if(!live){
            const arr:any = []    
            const usersX:any = []          
        const quere =await getDocs(quer)     
        substractRead(quere.size)
        quere.docs.map(async(x:any,i:any)=>{            
            if(x.data().userId!=='opt-out' && x.data().userId!==undefined && x.data().userId!==null){
                
             const ddd =(await getDoc(doc(collection(firebase,'users'),x.data().userId))).data();
             substractRead(1)
             if(ddd!==undefined){         
             usersX.push(ddd)
             }
            }
        }) 

        quere.docs.map(async(x:any,i:any)=>{            
            if(quere.docs.length==(i+1)){console.log(i,'okay let',x.id); setstartAfterId(x) }            
            if(i==0){console.log(i,'okay',x.id); setbeforeAfterId(x) }            
             const finalarr =x.data()
             if(finalarr['businessPath']==='ace-pick-a-part'){
                finalarr.mugshot =finalarr.signature;
                finalarr.mugshotUrl =finalarr.signatureUrl;
            }
             finalarr.mainId = x.id
            arr.push(finalarr)
        })         
        if(quere.size>0){
            setloaded(true)
        }  

        if(businessId!==undefined && businessId!==null && businessId!=='' && quere.size<=0){
            setloaded(true)
        }

        setusers(usersX)    
        setcheckIns(arr)
        setActiveCheckinsxx(arr[0])
        setloading(false) 
      }else {
           
        const arr:any = []    
            const usersX:any = []


         unsubscribev=  onSnapshot(quer,{includeMetadataChanges:false}, async(querySnapshot:any) => {                     
    
                     
            await querySnapshot.docChanges().map(async(xx:any,i:any)=>{   
              const x = xx.doc;
              if (xx.type === "added") {
             const minutDiffrence =    getMinutesBetweenDates(x.data().timestamp.toDate())
                 
            // **************** finishPlayText feature *****************
                try {
                    console.log('out in audio play',1);
                    if(( minutDiffrence<=15 && minutDiffrence>=0)){ 
                        console.log('enter in audio play');
                                                                    
                       const siteId =  x.data().siteId; 
                                           
                       sitesr.map(async(r:any)=>{
                        if(siteId===r.siteId ){   
                      
                                            
                         const finishPlay =   r.finishPlay!==undefined ? r.finishPlay : false;
                        //  const finishPlayText = r.finishPlayText!==undefined && r.finishPlayText!==null && r.finishPlayText!=='' ? r.finishPlayText : 'success checkin';
                            if(finishPlay===true){                                 
  
                                    await audioAppleElement.play(); 
                            
                            }
                        } 
                       })
                    } 
                } catch (error) {
                    console.log(error);
                    
                }
            // ***************** end finishPlayText ********

                if(i===0 && x.data().bannedCustomerOption!==undefined && x.data().bannedCustomerOption===true && minutDiffrence<=20){           
                         audioElement.volume =volume;
                         await audioElement.play();
                }                                            
                if(x.data().userId!=='opt-out' && x.data().userId!==undefined && x.data().userId!==null){                    
                 const ddd =(await getDoc(doc(collection(firebase,'users'),x.data().userId))).data();
                 if(ddd!==undefined){  
                 substractRead(1)       
                 usersX.push(ddd)
                 }
                }
            }
            }) 
            count++; 
            await querySnapshot.docChanges().map(async(xy:any,i:any)=>{        
                if (xy.type === "added") {                                         
                const x= xy.doc                   
                // if(xy.docs!==undefined && xy.docs.length!==undefined && xy.docs.length==(i+1)){console.log(i,'okay let',x.id); setstartAfterId(x) }            
                // if(i==0){console.log(i,'okay',x.id); setbeforeAfterId(x) }
                 const finalarr =x.data()
                 finalarr.mainId = x.id 
                 if(finalarr['businessPath']==='ace-pick-a-part'){
                    finalarr.mugshot =finalarr.signature;
                    finalarr.mugshotUrl =finalarr.signatureUrl;
                }
                 
                 if(querySnapshot.docChanges().length<=2){  
                    
                    arr.unshift(finalarr)
                    countsnapUpdate(finalarr)
                 }else{
                    arr.push(finalarr)
                 }
                              
               
                }
            })  
                console.log(querySnapshot,'okau28282');
            substractRead(querySnapshot.size)       
            if(querySnapshot.size>0){
                setloaded(true)
            }  
            
            if(businessId!==undefined && businessId!==null && businessId!=='' && querySnapshot.size<=0){
                setloaded(true)
            }
            console.log(arr,'pp');
            console.log(arr);
            
            arr.length =35             
            setusers(usersX)    
            setcheckIns(arr)
            setActiveCheckinsxx(arr[0])
            setloading(false) 
        
            });  
 

    }


                     
    }, 1200);

                    
} catch (error) {
            console.log(error,'getCheckiNs');
                
}

    }

const countsnapUpdate=(arr:any)=>{

     const datax= arr;
     
     if(datax.kind===undefined && datax.userId!='opt-out'){
        TotalNewSubscriberlocal1++; 
     }
     if(datax.kind!==undefined && datax.userId!=='opt-out' && datax.kind==='recheck' ){
               
        TotalReCheckinslocal3++; 
     }
     if(datax.userId=='opt-out' ){
        TotalOptOutslocal2++; 
     }

    TotalCheckins4 = TotalCheckins4+1
     setTotalNewSubscriber(TotalNewSubscriberlocal1);
     setTotalOptOuts(TotalOptOutslocal2);
     setTotalReCheckins(TotalReCheckinslocal3);
     setTotalCheckins(TotalCheckins4);
}

const getMinutesBetweenDates=(endDate:any)=> {  
        const diff =  new Date().getTime()-endDate.getTime();
    
        return (diff / 1000);
    }

const busienssChange=async(id:any)=>{
   
    try {
       await unsubscribev()
        console.log('unsubscribed');
    } catch (error) {
        console.log(error);
        
    }
    setloading(true)
        setbusinessId(id)
        setsiteId('')
        setpage(1)
        setbeforeAfterId(null)
        setstartAfterId(null)
        try {
                  window.location.hash=`?businessId=${id}&SiteId=`
        } catch (error) {
            console.log(error);
            
        }  
   await businesses.map((res:any)=>{
            if(res.businessId===id){             
                setsites(res.sites)

                if(res.sites.length>1){
                    setshowAllSites(true)
                }else{
                    setshowAllSites(false)
                }
                
                getCheckIns(null,null,searchQuery,id,'',limits,res.sites)                  
                setbusines(res)
                if(res.checkInsOptions!==undefined && (res.colormode!==undefined && res.colormode===true)){
                    res.checkInsOptions.buttonFontColor='#fff';
                    SetCheckInCss(res.checkInsOptions)
                    }else{
                        SetCheckInCss(object) 
                    }
                const el:any =document.querySelector('.bp5-table-quadrant-scroll-container') 
                if(res.darkmode!==undefined && res.darkmode===true){
                    setdarkMode(true)
                    OnchangeDarkMode()
               
                if(el!==undefined && el!==null){
                el.style.background='rgb(92, 112, 128)'
                }
                    }else{
                    setdarkMode(false)
                if(el!==undefined && el!==null){
                        el.style.background=  el.style.background=CheckInCss.tableBackgroundColor!==undefined && CheckInCss.tableBackgroundColor!=='' ? CheckInCss.tableBackgroundColor :'#fff'
                        }
                 }
                 
            }                
        
    })    
        


}

const siteChange=async(id:any)=>{
    try {
        await unsubscribev()
        console.log('unsubscribed');

        try {
        business.sites.map((re:any)=>{
            if(re.siteId===id){
                if(auth().accountData.permissions.role!=='admin'){      
                        openSwalAlert(`${business.path}__${re.path}`)
                                  
                }
            }
        })

            
    } catch (error) {
           console.log(error,'ok');
            
    }
    } catch (error) {
        console.log(error);
        
    }

    setloading(true)
        setsiteId(id)
        setpage(1)
        setbeforeAfterId(null)
        setstartAfterId(null)
        getCheckIns(null,null,searchQuery,businessId,id,limits,sites)
    try {
            window.location.hash=`?businessId=${businessId}&SiteId=${id}`
  } catch (error) {
      console.log(error);
      
  }

} 



const changeColumnSetting=(name:any,bool:boolean)=>{
    const t= tableOptions.filter((x:any)=>{
           if(x.name==name){
               x.enabled=bool;
           }
           return x
       });
       settableOptions(t)
   }

const changeColumnPreviewSetting=(name:any,bool:boolean)=>{
    const t= previewOptions.filter((x:any)=>{
           if(x.name==name){
               x.enabled=bool;
           }
           return x
       });
       setpreviewOptions(t)
   }


const PrevioudPage=()=>{
    setpage(page-1);
    setloading(true)  
    getCheckIns(beforeAfterId,'previous',searchQuery,businessId,siteId,limits,sites)     
}
const NextPage=()=>{
    setpage(page+1);
    setloading(true)  
        getCheckIns(startAfterId,'Next',searchQuery,businessId,siteId,limits,sites) 
}


const limitsHandle=(v:any)=>{   
    setlimits(v)
    setpage(1)
    getCheckIns(null,null,searchQuery,businessId,siteId,v,sites)  
}

const SearchUser=()=>{
    setpage(1)
    try {

    if(searchQuery[0]==='mobile' && searchQuery[1]!==null && (searchQuery[1].length>10 || searchQuery[1].length<10)){
    alert('Please search for a full 10-digit phone number')
    }else{
        setloading(true)
    getCheckIns(null,null,searchQuery,businessId,siteId,limits,sites)  
    }
            
} catch (error) {
        
}
}


const SearchUserByDate=()=>{
    setpage(1)
    setloading(true)
    getCheckIns(null,null,searchDate,businessId,siteId,limits,sites)  
}

const ResetFilter=async()=>{
    try {
        TotalNewSubscriberlocal1=0;
        TotalOptOutslocal2=0;
        TotalReCheckinslocal3=0;
        TotalCheckins4=0;

       await unsubscribev()
    } catch (error) {
        console.log(error);
        
    }
    setloading(true)
    setsearchQuery(['mobile',null])
    setsearchDate(['timestamp',null])
    setpage(1)
    setIssearchingEnabledByName(false)
    setIssearchingEnabledByMobile(false)
    setIssearchingEnabledByDate(false)
    setTotalNewSubscriber(0);
    setTotalOptOuts(0);
    setTotalReCheckins(0);
    setTotalVoids(0)
    setTotalCheckins(0);
    getCheckIns(null,null,['timestamp',null],businessId,siteId,limits,sites)
      
}

const cnkrvCountsTypeHandle=async(v:any)=>{
    setcnkrvCountsType(v)
    UpdateCounts(v,businessId,siteId)
    
}

const UpdateCounts=async(v:number,businessIdx:any,siteIdx:any)=>{
console.log('hit count',businessIdx,siteIdx);

    const da = new Date();   
    da.setDate(da.getDate() - v);
    da.setHours(23)
    da.setMinutes(59)
    da.setSeconds(59)
    let quer:any=[];
    if(siteIdx!==null && siteIdx!==''){ 
        quer=  await getDocs(query(collection(firebase,'checkIns'),where('businessId','==',businessIdx) , where('siteId','==',siteIdx),where('timestamp','>=',da)))
    }else{
    quer= await getDocs(query(collection(firebase,'checkIns'),where('businessId','==',businessIdx),where('timestamp','>=',da))) 
    } 
    let quervoid:any=[];
    if(siteIdx!==null && siteIdx!==''){ 
        quervoid=  await getDocs(query(collection(firebase,'voidedCheckIns'),where('businessId','==',businessIdx) , where('siteId','==',siteIdx),where('voidedAt','>=',da)))
    }else{
        quervoid= await getDocs(query(collection(firebase,'voidedCheckIns'),where('businessId','==',businessIdx),where('voidedAt','>=',da))) 
    } 
  
   let TotalNewSubscriberlocal=0
   let  TotalOptOutslocal=0
   let TotalReCheckinslocal=0

   try {
    substractRead(quer.size+quervoid.size)
   } catch (error) {
    console.log(error,'UpdateCounts'); 
   }

   quer.docs.map((x:any)=>{

    const datax= x.data();
    // console.log(datax.timestamp.toDate(),datax.kind,datax.mobile);
    
    if(datax.kind===undefined && datax.userId!='opt-out'){
        TotalNewSubscriberlocal++; 
    }
    console.log(datax.kind,'8909098');
    
    if(datax.kind!==undefined && datax.userId!=='opt-out' && datax.kind==='recheck' ){
            
        TotalReCheckinslocal++; 
    }
    if(datax.userId=='opt-out' ){
        TotalOptOutslocal++; 
    }
   })

    TotalNewSubscriberlocal1=TotalNewSubscriberlocal
    TotalOptOutslocal2=TotalOptOutslocal
    TotalReCheckinslocal3=TotalReCheckinslocal
    TotalCheckins4 =quer.size

    setTotalNewSubscriber(TotalNewSubscriberlocal1);
    setTotalOptOuts(TotalOptOutslocal2);
    setTotalReCheckins(TotalReCheckinslocal3);
    setTotalVoids(quervoid.size)
    setTotalCheckins(TotalCheckins4);

}

const setActiveCheckinFun=(ix:any)=>{
    
    try {       
              
    setActiveCheckinsxx(checkIns[ix[0]['rows'][0]])                 
    } catch (error) {
          console.log(error);
              
    }
}

const changeMode=(mode:any)=>{
    localStorage.setItem('S_check_ins_v1_mode',mode)
    setlive(mode==='realTime')
    setpage(1)
    window.location.href=''
}
const formatString = (path:any)=>{
    const strArr = path.split('-')
    strArr.forEach((ele:any, index:any) => {
      strArr[index] = ele.charAt(0).toUpperCase() + ele.slice(1);
    });
    return strArr.join(' ');
  }


const updateFieldCheckIns=(field:any,userId:any,mainId:any)=>{

    const allcheckInsFiltered= checkIns.filter((checkin:any)=>{
        if(checkin['userId']===userId){  
            if(field.bannedCustomerOption!==undefined){          
                checkin.bannedCustomerOption=field.bannedCustomerOption 
            }
            if(field.checkedIdCustomer!==undefined){          
                checkin.checkedIdCustomer=field.checkedIdCustomer 
            }                        
             
            if(checkin['mainId']===mainId){                
                try {  
                    if(field.markForTheft!==undefined){          
                        checkin.markForTheft=field.markForTheft 
                    }
                    if(field.markForSellingCar!==undefined){          
                        checkin.markForSellingCar=field.markForSellingCar 
                    }
                    if(field.militaryFirstResopnder!==undefined){          
                       checkin.militaryFirstResopnder=field.militaryFirstResopnder 
                    }
                setTimeout(() => {
                    setActiveCheckinsxx(checkin)  
                },1000); 
          
                } catch (error) {
                          console.log(error);
                              
                }
            }
        }
    return checkin
    });
    const allusers= users.filter((user:any)=>{    
        if(user['userId']===userId){
            if(field.bannedCustomerOption!==undefined){
             user.bannedCustomerOption=field.bannedCustomerOption 
            }
            if(field.checkedIdCustomer!==undefined){
                user.checkedIdCustomer=field.checkedIdCustomer 
            }
        }
    return user
    });

setusers(allusers) 
setcheckIns(allcheckInsFiltered)


}

const openKiosk=(buisenssId:any,siteId:any)=>{
 let sitepath= '';
 business.sites.map((row:any)=>{
    if(row.siteId===siteId){
        sitepath=row.path
    }
 })
 if(sitepath!==''){
    window.open(urls.baseUrlKiosk+'/'+business.path+'/'+sitepath)
 }
    
}

const searchByNumber=(val:any,bool:any)=>{

    getCheckIns(null,null,['mobile',val],businessId,siteId,limits,sites) 

}

const tableoptionorderchange=(x:any,vx:any)=>{
if((x.order>9 && vx=='down' ) || (x.order<=0 && vx=='up')){
alert('Column order number should be < 9 and > 0')
}else{

    
 const rows =   tableOptions.filter((v:any)=>{
                if(v.name==x.name){               
                v.order= vx==='up' ? parseInt(v.order)-1 : parseInt(v.order)+1
                
                }
                    return v;
    })
settableOptions(rows);

}
}

const capitalize=(s:any)=>{
    return s && s[0].toUpperCase() + s.slice(1);
}
    return(
        <div className='checkin-main container-fluid' style={{background:CheckInCss.mainBackgroundColor,flexDirection:window.innerWidth<=720 ? 'column': 'row'}}>  
        <Dialog title={'Help'} icon='info-sign' isOpen={modeinfo!=''} onClose={()=>setmodeinfo('')}>
        <DialogBody>
            <div>
       
            <p><b>Realtime Mode: </b>
            Update latest checkIn data without refreshing the page.
            </p>
            <p><b>Reporting Mode: </b>
            Refresh page or press reset button to show latest checkIn data. User also can search record by name/phone number/date.
            </p>
            </div>
            </DialogBody>
        </Dialog>       
          <div className='col-35' style={{marginRight:'20px',minWidth:'440px',border:'unset',background:CheckInCss.leftSideBgColor}}>
            <ActiveCheckIns 
            auth={auth}
            checkIn={ActiveCheckinsxx}
            checkInsAll={checkIns}
            previewOptions={previewOptions}
            load={loaded}
            refreshCheckIn={()=>ResetFilter()}
            updateField={(field:any,userId:any,mainId:any)=>updateFieldCheckIns(field,userId,mainId)}
            loading={loading}
            siteId={siteId}
            sites={sites}
            users={users}
            businessId={businessId}
            business={business}
            searchByNumber={(value:any,bool:any)=>searchByNumber(value,bool)}
            />
          </div>
          <div className='col-65'  style={{border:'unset',background:CheckInCss.mainBackgroundColor}}>

            <div className='altoolssection'>
            <div>
                <div className='tools' >
                <select  disabled={loading} className='selectBusiness' onChange={(e)=>busienssChange(e.target.value)}>
                     {businesses.map((val:any,i:any)=>(val.sites!==undefined && val.sites!==null && val.sites.length>0 ?<option key={i} selected={val.businessId===businessId} value={val.businessId}>{val.businessName}</option>:''))}
                </select>
                <select  disabled={loading} className='selectBusiness'  onChange={(e)=>siteChange(e.target.value)}>
                    {showAllSites ?<option value=''>all</option>:''}
                     {sites!==undefined && sites!==null ? sites.map((val:any,i:any)=>(<option selected={val.siteId===siteId}  value={val.siteId}>{val.path}</option>)):''}
                </select>     
                <Button style={{    margin:'5px'}} onClick={()=>setopenSetting(true)}><Icon icon='cog'/></Button>

              {!live ?  <Button style={{    margin:'3px'}} onClick={()=>changeMode('realTime')} intent='success'>Change to Real Time Mode</Button>:''}
              {!live ?  <Button  style={{background:CheckInCss.buttonColor,margin:'3px',color:'#fff'}} disabled={loading}  loading={loading} onClick={()=>ResetFilter()}>Load Latest Check-Ins</Button>:''}
              {live ?  <Button style={{    margin:'3px'}} intent='success' onClick={()=>changeMode('reporting')}>Change to Reporting Mode</Button>:''}
              {/* <Button style={{    margin:'5px'}}  onClick={()=>{setmodeinfo('show')}} ><Icon icon='info-sign'/></Button> */}


              {siteId ?  <Button style={{background:siteId=='' ? CheckInCss.buttonColor: 'linear-gradient(94.46deg,#0d47f0,#3f79cb)',margin:'5px',color:'#fff'}} onClick={()=>openKiosk(businessId,siteId)}>Kiosk</Button>:''}
                </div>
            <div className='tools'>
                    <select className='selectBusiness'  style={{background:CheckInCss.buttonColor,color:CheckInCss.buttonFontColor}}  onChange={(e)=>cnkrvCountsTypeHandle(e.target.value)}>
                    <option value={1}>Daily</option>                     
                    <option value={7}>Weekly</option>   
                    <option value={31}>Monthly</option>   
                        </select> 
                <div className='parentDiv' style={{background:CheckInCss.buttonColor}}>
                    <div className='child1'  >Check-Ins</div>
                    <div className='child2'>{TotalCheckins}</div>
                </div>
                <div className='parentDiv' style={{background:CheckInCss.buttonColor}}>
                    <div className='child1'  >New VIP Subscribers</div>
                    <div className='child2'>{TotalNewSubscriber}</div>
                </div>
                <div className='parentDiv' style={{background:CheckInCss.buttonColor}}>
                    <div className='child1'  >Kiosk Opt-Outs</div>
                    <div className='child2'>{TotalOptOuts}</div>
                </div>
                <div className='parentDiv' style={{background:CheckInCss.buttonColor}}>
                    <div className='child1'  >Re-Check</div>
                    <div className='child2'>{TotalReCheckins}</div>
                </div>
                <div className='parentDiv' style={{background:CheckInCss.buttonColor}}>
                    <div className='child1'  >Voids</div>
                    <div className='child2'>{TotalVoids}</div>
                </div>
            </div>
               {!live? <div className='tools'>
                    <select disabled={loading} className='selectBusiness' style={{background:CheckInCss.buttonColor,color:CheckInCss.buttonFontColor}} onChange={(e)=>setsearchQuery([e.target.value,searchQuery[1]])}>                  
                    <option value={'mobile'} selected={searchQuery[0]==='mobile'}>Phone Number</option>  
                        <option value={'name.displayName' } selected={searchQuery[0]==='name.displayName'}>Name</option>     
                    </select>
                    <InputGroup style={{minHeight:'35px'}}
                    type='text'  
                    value={searchQuery[1]!==null ? searchQuery[1]: ''}
                    placeholder={searchQuery[0]==='mobile' ? 'Enter mobile' :'Enter name'}
                    onChange={(e)=>setsearchQuery([searchQuery[0],capitalize(e.target.value)])}                  
                    rightElement={<Button  disabled={loading} loading={loading} onClick={()=>SearchUser()}  ><Icon icon='search' size={20}/></Button>}                    
                    /> 
                    <InputGroup style={{minHeight:'35px'}}
                    type='date' 
                    onChange={(e)=>setsearchDate(['timestamp',e.target.value])}  
                    value={searchDate[1]!==null ? searchDate[1] : ''}        
                    rightElement={<Button disabled={loading} loading={loading} onClick={()=>SearchUserByDate()}  ><Icon icon='search' size={20}/></Button>}                    
                    /> 
                    <Button disabled={loading} style={{background:CheckInCss.buttonColor,color:'#fff'}}  loading={loading} onClick={()=>ResetFilter()} className='m-4' intent='none' small={true}>Reset</Button>
                </div> :''} 
                <div className='tools' style={{display:checkIns.length<=0 && IssearchingEnabledByName ?'block':'none'}}>
                    <p className='red'>
                    {checkIns.length<=0 && IssearchingEnabledByName ? 'For best results, please search for the full first and last name, just the full first name or just the full last name.' :''}
                    {checkIns.length<=0 && IssearchingEnabledByDate ? '' :''}
                    {checkIns.length<=0 && IssearchingEnabledByMobile ? '' :''}
</p>
                    </div>              
          </div>
            <div className='tools'>
                <div className='parentDiv'  style={{backgroundColor:'unset'}} >
                        <div className='child1'><Button disabled={loading || page<=1 || live} className='br-50' onClick={()=>PrevioudPage()}><Icon icon='arrow-left' size={15}/></Button></div>
                        <div className='child3'>{page}</div>
                        <div className='child1'><Button disabled={loading || limits!=checkIns.length || live} className='br-50' onClick={()=>NextPage()}><Icon icon='arrow-right' size={15}/></Button></div>
                </div>
                <div className='parentDivsecond'>
                        <select className='selectBusiness pageselect' onChange={(e)=>limitsHandle(parseInt(e.target.value))}>
                            <option value={35}>Show 35</option>                     
                            <option value={100}>Show 100</option>     
                            <option value={200}>Show 200</option>   
                            <option value={500}>Show 500</option>   
                        </select>
                </div>
            </div>
            </div>
            <div className='table' style={{height: '69vh',position:'relative'}}>
             <CheckinsTable
               TableStyle={CheckInCss}
               onSetActiveCheckIn={(e:any)=>setActiveCheckinFun(e)}
               numRows={checkIns.length}
               fontSize={fontSize}
               tableOptions={tableOptions}
               loading={loading}
               businessId={businessId}
               users={users}
               checkIns={checkIns}
               business={business}
               />
            </div>
          </div>


        <Dialog title="View Options" onClose={()=>setopenSetting(false)} isOpen={openSetting} icon="info-sign">
            <DialogBody>
            {business.bannedWarningAlert!==undefined && business.bannedWarningAlert===true ? <span><H5>Banned Customer Entry Alert Volume</H5>
            <input type='range' min={0} max={1} step="0.1" value={volume} onChange={(e)=>{  localStorage.setItem('volume',e.target.value); setVolume(e.target.value) }}/></span> :''}
                <H2>Font Size:</H2>
                <div className='parentDivforfont' style={{background:CheckInCss.buttonColor}} >
                        <div className='child1'><Button disabled={loading || fontSize<=10} className='br-50' onClick={(e)=>setfontSize((fontSize-1))}><Icon icon='arrow-left' size={15}/></Button></div>
                        <div className='child3'>{fontSize}px</div>
                        <div className='child1'><Button disabled={loading || fontSize>25} className='br-50' onClick={(e)=>setfontSize((fontSize+1))}><Icon icon='arrow-right' size={15}/></Button></div>
                </div>
                <br/>
                <div  className='rowxTag'>
                <div className='col-50'>
                   <p key={89898} className='bordersett text-center'><h4><b>Left Panel Fields</b></h4></p>
                   {
                previewOptions.map((x:any,i:any)=>
                        <p key={i} className='bordersett d-flex f-21'>&nbsp;{formatString(x.name)}&nbsp;<Switch onChange={(e:any)=>changeColumnPreviewSetting(x.name,e.target.checked) } checked={x.enabled}/> </p>
                )
              }
                </div>


                    <div className='col-50'>
                    <p key={8998} className='bordersett text-center'><h4><b>Right Table Columns</b></h4></p>
             
              { 
                tableOptions.sort((a:any,b:any)=>a.order-b.order).map((x:any,i:any)=>
                        <p key={i} className=' bordersett d-flex f-21'>&nbsp;{formatString(x.name)}&nbsp;<Switch onChange={(e:any)=>changeColumnSetting(x.name,e.target.checked) } checked={x.enabled}/>
                        
                        <ButtonGroup style={{marginLeft:'auto'}}>
                            <Button style={{padding:'9px'}} small={true} disabled={x.order<=1} intent='primary' onClick={(e)=>tableoptionorderchange(x,'up')}><Icon icon='circle-arrow-up' size={25}/></Button>
                            <Button style={{padding:'9px'}}small={true} disabled={x.order>=9}  intent='primary' onClick={(e)=>tableoptionorderchange(x,'down')}><Icon icon='circle-arrow-down'  size={25}/></Button>
                        </ButtonGroup>                         
                          </p>
                )
              }
                   </div>
                  
              </div>
            </DialogBody>           
        </Dialog>
  
        </div>
    )



}
