
import React, { useEffect, useState }  from 'react';
import { Button, Card, Classes, Dialog, Elevation, HTMLTable, Icon, InputGroup, Label, Tab, Tabs } from '@blueprintjs/core';
import { firebase, urls } from '../global/firebase';
import { useAuthUser } from 'react-auth-kit';
import { LocationCouponData } from './LocationCouponData/LocationCouponData';
import { collection, getDocs, query } from 'firebase/firestore';
import LocationTrasactionsLogData from './LocationCouponData/LocationTrasactionsLogData';
let interval:any;
 const Pointsystemquery=()=>{

  const [key,setkey]=useState('');
  
  const [keyx,setkeyx]=useState('');
  const [date,setdate]=useState('');
    const [mobile,setmobile]=useState('');
    const [url,seturl]=useState('');
    const [busdaata,setbusdaata]=useState([] as any);
    const [bool,setbool]=useState(false);
    const [disable,setdisable]=useState(false);
    const [openlogPage,setopenlogPage]=useState(false);
    const [datalog,setdatalog]=useState([])
    const [firstname,setfirstname]=useState('')
    const [lastname,setlastname]=useState('')
    const [datex,setdatex]=useState('')
    const [conc,setconc]=useState('')
    const auth:any = useAuthUser();
    const [units,setunits]=useState([])
    const [infodata,setinfodata]=useState('')
    const [user,setuser]=useState({permissions:{role:''}})

    const role = auth().accountData.permissions.role
    useEffect(() => {
      if(!bool){
        setbool(true)
        p1s5e6_get_businesses()
        getDocs(query(collection(firebase,'p1s5cpuusage'))).then((res:any)=>{
          const arr:any =[]
          if(res.empty==false){
            res.docs.map((resx:any)=>{
              console.log(resx.data());
              
            const dd = new Date(resx.data().date.seconds*1000).toLocaleDateString();    
            let totalx =resx.data().total
            let usedx =resx.data().used
            let freex =resx.data().free
            console.log(totalx,'okkko');
            
            totalx =totalx/ (1024 ** 2)  
            usedx =usedx/ (1024 ** 2) 
            freex =freex/ (1024 ** 2) 
            console.log(totalx);
             
            totalx =Math.round(totalx).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") 
            usedx =Math.round(usedx).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")        
            freex =Math.round(freex).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") 
            const businessdatax:any=[]
        
            let businessdatabrfore =resx.data().businessdatabefore!==undefined ? Math.round(JSON.parse(resx.data().businessdatabefore).used/ (1024 ** 2)):0;
            
              if(resx.data().businessdata!=undefined){
                JSON.parse(resx.data().businessdata).map((xg:any)=>{
                  const usedmemo =Math.round(xg['used']/ (1024 ** 2))-businessdatabrfore 
                  businessdatabrfore =Math.round(xg['used']/ (1024 ** 2))
                  xg.used =usedmemo
                  businessdatax.push(xg)
                })
        
              }


            arr.push({use:resx.data().percent,date:dd,available:freex,free:freex,used:usedx,total:totalx,businessdata:JSON.stringify(businessdatax)})
            })
            console.log(arr);
            
            setunits(arr)
          }
          
        })

      }
 
    },[busdaata])

const p1s5e6_get_businesses=async()=>{

   const requestOptions:any = {
    method: 'POST',
    redirect: 'follow'
  };

  const userState = localStorage.getItem('_auth_state');
  const user = userState!==null ?   JSON.parse(userState).accountData:null;
  setuser(user)
 await fetch(urls.pointsystem+'p1s5e6_get_businesses', requestOptions)
    .then((response) => response.json())
    .then((result) => {
      const arrx:any= [];
      if(user!==null && userState!==undefined && userState!==null){        
       Object.keys(result).forEach((k,i) => {            
        if((user.permissions.businesses.includes(result[k].texn_business_uid) || user.permissions.businesses.length<=0) && result[k].is_disabled===0){
         
          arrx.push(result[k])         

        }
      });    
      

   
      arrx.sort((a:any,b:any)=>(a.name.localeCompare(b.name)))
      console.log(arrx,'iiiii');
      
      setbusdaata(arrx);
      
    }
    
    });



  }




const openwin=()=>{    

  let con = '';
  try {

  busdaata.map((x:any)=>{
   const  keyx = key.split('_')[1];
   console.log(keyx,'1');
   
   if(x.s3_ids[0]!==undefined && x.s3_ids[0]===keyx){
    console.log(x.s3_ids[0],'1');
if(x.coupon_rule_settings!==undefined && x.coupon_rule_settings.CET1!==undefined && x.coupon_rule_settings.CET1.coupon_brackets!==undefined){
  x.coupon_rule_settings.CET1.coupon_brackets.map((r:any)=>{

    con+=`${r[2]}% - ${r[0]} Points,`


  });
}else if(x.coupon_rule_settings!==undefined && x.coupon_rule_settings.CET2!==undefined && x.coupon_rule_settings.CET2.coupon_value){

  
    con+=`${x.coupon_rule_settings.CET2.coupon_value}% - ${x.coupon_rule_settings.CET2.number_of_transactions} Trasactions - $${x.coupon_rule_settings.CET2.minimum_purchase} Minimum-Purchase/Trasaction,`


}
   }

  })
      
} catch (error) {
    console.log(error);
    
}


  // const  url = `/LocationCouponData?yard_api_key=${key}&val=${con}`;
  if(key=='' || key.length>20){
   alert('please enter valid key')
  }else{

    setconc(con)

  
  }

}

const openwinnew=()=>{    

  setopenlogPage(false);

  // const  url = `/LocationCouponData?yard_api_key=${key}&val=${con}`;
  if(keyx=='' || keyx.length>20){
   alert('Please Select Valid key')
  }
  else{
    setTimeout(() => {
      

    setopenlogPage(true);    }, 1000);
  }

}



function getLast7Days() {
  let daysChecked = 0;

    const today = new Date(); // Current date
    const last31Days = [];

    for (let i = 0; i < 31; i++) {
        const date = new Date();
        date.setDate(today.getDate() - i); // Subtract days
        const formattedDate = date.toISOString().split('T')[0]; // Format as YYYY-MM-DD

        last31Days.push(formattedDate);
    }



  const arr:any = []
  last31Days.map((x:any)=>{    
    
    arr.push({date:x,day:new Date(x).getDate()})
  })
  console.log(arr)
  return arr;
}


const RunBackup =async()=>{
  setdisable(true)
  if(window.confirm('Are you sure to run this backup.')){
  const myHeaders = new Headers();
  
  const requestOptions:any = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
    mode:'no-cors'
  };

 clearInterval(interval); 
 interval =setInterval(() => {
  p1s5e6_get_datalog()
  
 }, 20000); 
 await fetch(urls.pointsystem+'BackupRunCron?day='+date,requestOptions).then(async(e)=>{
    console.log(e);
    setdisable(false)
    
  }).catch((e)=>{
      console.log(e);
      setdisable(false)
  })
  }
}


const p1s5e6_get_datalog=async()=>{

  const requestOptions:any = {
   method: 'GET',
 };


await fetch(urls.pointsystem+'p1s5e6_get_backuplog?day='+date, requestOptions)
   .then((response) => response.json())
   .then((resultx) => {
     
     console.log(resultx.res.length);

if(resultx.res.length<=0){
alert('Proccess complete');
clearInterval(interval);
}
     setdatalog(resultx.res);

   setTimeout(() => {
    window.scrollTo({ top: 100000, behavior: 'smooth' });
   }, 500);  

     
   })
   




 }


const openinfo=(data:any)=>{
  console.log(data);
  
if(data.businessdata!==undefined){
  setinfodata(data.businessdata)
}

}

 const clearfilter=()=>{

  setmobile('')
  setfirstname('')
  setlastname('')
  setdatex('')
 }


 const getfiledateOnly=(date:any)=>{

  const d = new Date(date);
  return `${d.getDate()}_${d.getMonth()+1}`

 }

    return(
      <div className="main_div_report" style={{width:'95%'}}>
            
            <br/>          
            <Tabs
                animate={true}
                key={"vertical"}
                vertical={false}
            >

       <Tab id="ng" title="Point System Dashboard" panel={
       
       <Card interactive={true} elevation={Elevation.TWO} >  
         <h2>Point System Dashboard</h2>
         <div style={{display:'inline-flex',marginBottom:'10px'}}>          
         
            <select name="name" id="ifd" onChange={(e:any)=>{console.log(e.target.value);
             setkey(e.target.value); setconc('')}}>
              <option value={''}>Select Business</option>
              { busdaata.map((rz:any)=>
                  <option value={'S3Crush_'+rz.s3_ids[0]}>{rz.name}</option>
              )}
            </select>&nbsp;&nbsp;
          
           
        

            
            <Button disabled={key==''}  intent='success' onClick={()=>openwin()}>Continue</Button>
         </div>
         <br/>

    {key!==undefined && key!=='' && conc!=='' ? <LocationCouponData keyd={key} conc={conc}/> :''}
         </Card>
       }/>



<Tab id="ng1" title="Point System Transactions History" panel={
       
       <Card interactive={true} elevation={Elevation.TWO} >  
         <h2>Point System Transactions History</h2>
         <div style={{display:'inline-flex',marginBottom:'10px'}}>          
         
            <select name="name" id="ifd" onChange={(e:any)=>{console.log(e.target.value);
             setkeyx(e.target.value); setconc('')}}>
              <option value={''}>Select Business</option>
              { busdaata.map((rz:any)=>
                  <option value={'S3Crush_'+rz.s3_ids[0]}>{rz.name}</option>
              )}
            </select>&nbsp;&nbsp;
          
           
        

            
            <Button disabled={keyx==''}  intent='success' onClick={()=>openwinnew()}>Continue</Button>
         </div>
         <br/>

    {keyx!==undefined && keyx!=='' && openlogPage ? <LocationTrasactionsLogData keyd={keyx}/> :''}
         </Card>
       }/>


{role=='admin'?<Tab id="ngt" title="Point System Backup Run" panel={
       
       <Card interactive={false} elevation={Elevation.TWO}>  
         <div >
            <h1>Select Backup Date To Run</h1>
            <Label>Select Backup Date  * <br/>
            <select name="name" id="ifd" onChange={(e:any)=>{ setdate(e.target.value)}}>
              <option value={''}>Select Date</option>
              { getLast7Days().map((rz:any)=>
                  <option value={rz.date}>{rz.date}</option>
              )}
            </select>
            </Label>
            
          {date!='' ?<HTMLTable border={1}>
              <tr>
                <th>Business</th>
                <th>Files List To Proccess:</th>
              </tr>
              {busdaata.map((rz:any)=>
              <tr>
                <td>{rz.name}</td>
                <td>{'backup_'+rz.s3_ids[0]+'_'+getfiledateOnly(date)+'_dailytrasactions.xml'}</td>
                <td>
        <a target="_blank"  href={'http://45.79.157.162/saved7daysdata/'+'backup_'+rz.s3_ids[0]+'_'+getfiledateOnly(date)+'_dailytrasactions.xml'} ><Button intent={'primary'} rightIcon={'eye-open'}>View</Button></a>
       &nbsp;
        <Button intent={'success'} onClick={()=>window.open(urls.toolsandautomated+'downloadxmlfile?url=http://45.79.157.162/saved7daysdata/'+'backup_'+rz.s3_ids[0]+'_'+getfiledateOnly(date)+'_dailytrasactions.xml','download.xml')} rightIcon={'download'}>Download</Button>
        
        </td>
              </tr>)}
            </HTMLTable>:''}
            <br/>
            <Button disabled={(date=='' || disable) && user.permissions.role!='admin'}   intent='success' onClick={()=>RunBackup()}>{disable ? 'Stay on page until proccess  not complete.It takes 15-20 minutes':'Run'}</Button>
         </div>

         {disable ? 
      <div id="datalog"
      style={{ padding: '10px',background: '#000',color: '#01d101', marginTop: '10px'}}
      >
  {datalog.map((x:any)=>
    <p>{x}</p>
  )}
      </div>
    :'' } 
         </Card>
       }/>:''}

    
  
{role=='admin'?<Tab id="ngt3" title="P1S5 CPU Usage" panel={


<div>
<Dialog
title="Infomation"
icon="info-sign"
isOpen={infodata!==''}
style={{width:'90%'}}                
onClose={()=>setinfodata('')}>
<div className={Classes.DIALOG_BODY} style={{overflow:'auto'}}>
  
<table border={1} style={{borderCollapse:'collapse'}}><thead><tr><th>Business</th><th>Used</th><th>Status</th></tr></thead><tbody>

{infodata!=='' ?JSON.parse(infodata).map((x:any)=>(<tr>
  <td>{x['name']}</td>
  <td>{x['used']} MB</td>
  <td>{x['msg']}</td>
</tr>)):''}

</tbody></table>

  </div>
  </Dialog>



<HTMLTable >
<thead>
  <tr>
  <th>Date</th>
  <th>Total Memory</th>
  <th>Used Memory</th>
  <th>Free Memory</th>
    <th>Usage%</th>
    <th>Action</th>
  </tr>
</thead>
<tbody>
  {units.length>0 ? units.map((x:any)=>(<tr>
    <td>{x.date}</td>
    <td>{x.total} MB</td>
    <td>{x.used} MB</td>
    <td>{x.free} MB</td>
    <td><ProgressBar key={'idx'} bgcolor={'#1c82e5'} completed={x.use} /> </td>
    <td><Button disabled={x.businessdata.length<=5} intent={'primary'} onClick={()=>openinfo(x)}><Icon icon={'eye-open'}/></Button></td>
   
  </tr>)):''}
  
</tbody>
</HTMLTable>
</div>
}/>:''}

</Tabs>

        </div>
    )
}




export default Pointsystemquery





const ProgressBar = (props:any) => {
  const { bgcolor, completed } = props;

  const containerStyles = {
    height: 20,
    border:'1px dotted #c9c7c7',
    backgroundColor: "#e0e0de",
    borderRadius: 50,
  }

  const fillerStyles = {
    height: '100%',
    width: `${completed}%`,
    backgroundColor: bgcolor,
    borderRadius: 'inherit',
   borderRight:'1px dotted #c9c7c7',
   textAlign:'center',
  }

  const labelStyles = {
    padding: 5,
    color: 'white',
    fontWeight: 'bold'
  }

  return (
    <div style={containerStyles}>
      <div style={{
    height: '100%',
    width: `${completed}%`,
    backgroundColor: bgcolor,
    borderRadius: 'inherit',
   borderRight:'1px dotted #c9c7c7',
   textAlign:'center',
  }}>
        <span style={labelStyles}>{`${completed}%`}</span>
      </div>
    </div>
  );
};
