import { Button, ButtonGroup, Classes, Dialog, HTMLTable, Icon, InputGroup } from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import "../../assets/css/style.css";
import { urls } from "../../global/firebase";
import * as XLSX from "xlsx";
interface Transaction {
    amount_spent: string;
    business_uid: string;
    consumer_uid: number;
    phone_number:string;
    created_date: string;
    internal_id:string;
    transaction_date:string;
    transaction_details:string;
    transaction_id:string

}
let page =1;
const LocationTransactionsLogData = (props: any) => {
  const [loading, setLoading] = useState(false);
  const [dataRows, setDataRows] = useState<Transaction[]>([]);
  const [fetchLog, setFetchLog] = useState(true);
  const [model, setmodel] = useState(false);
  const [tooltipopen, settooltipopen] = useState(false);
  const [tooltiptext, settooltiptext] = useState('');
  const [rows,setrows]=useState([])
const [spinner,setspinner]=useState('');
const [nomoreresult, setnomoreresult] = useState(false);
const [mobile,setmobile]=useState('');
  useEffect(() => {
    page=1
    if (fetchLog) {
        getlog(page,'',20);
    }
  }, [fetchLog]);


const getlog=(page:any,q:any,limit:any)=>{

  

    setLoading(true);
    fetch(urls.pointsystem+"gettrasactionshistory?page="+page+'&q='+q+'&limit='+limit)
      .then((response) => response.json())
      .then((data) => {
          const arr =  q==''? dataRows:[]
          if(data.length>0){
          data.map((x:any)=>{
              arr.push(x)
          })
        }else{
            setnomoreresult(true)
        }
        setDataRows(arr);
        setFetchLog(false);
        if(limit==100000){
          setTimeout(() => {

          const table = document.getElementById("myTable");
          const workbook = XLSX.utils.table_to_book(table, { sheet: "Sheet1" });
          XLSX.writeFile(workbook, "table.xlsx");            
        }, 2000);
        }
      })
      .catch((error) => console.log("Error fetching data:", error))
      .finally(() => {setLoading(false);setspinner('')});
  }


  const styleTh = {
    padding: "10px",
    border: "1px solid #bdbdbd",
    textAlign: "center" as const,
    fontWeight: "600",
    borderRadius: "3px",
  };

  const styleTh2 = {
    ...styleTh,
    fontWeight: "800",
    color: "#d32f2f",
  };

  const formatDate = (date: string) => {
    const da = new Date(date);
    const day = da.getUTCDate().toString().padStart(2, "0");
    const month = (da.getUTCMonth() + 1).toString().padStart(2, "0");
    const year = da.getUTCFullYear();
    return `${month}/${day}/${year}`;
  };

  const formatAmount = (x: any) => {
    return Math.round(parseInt(x)).toLocaleString("en");
  };

  const formatAmountx = (x: any) => {

    return Math.round(parseInt(x)).toLocaleString("en").replaceAll('-','');
  };

  

  const tooltip=(tooltiptext:any)=>{
    settooltipopen(true)
settooltiptext(tooltiptext.tooltiptext);
  
  }

  const OpenPopup=(x:any)=>{   
    setmodel(true);
    const rows =JSON.parse(x.transaction_details);
    setrows(rows)
  }
   const csvDownloads=()=>{
    if(mobile!=''){
      setTimeout(() => {

        const table = document.getElementById("myTable");
        const workbook = XLSX.utils.table_to_book(table, { sheet: "Sheet1" });
        XLSX.writeFile(workbook, "table.xlsx");            
      }, 2000);
    }else{
    getlog(page,'',100000);
    }

        
        }

  const handleScroll = (e:any) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    const position = Math.ceil(
        (scrollTop / (scrollHeight - clientHeight)) * 100
    );

    let timer;
    if(position==100){
        // if((this.state.datex=='' && this.state.datexx=='')){
            if(!nomoreresult){
        e.target.scrollTop=e.target.scrollTop-300
        clearTimeout(timer);
        setspinner('paginationload')
        page += 1
        timer = setTimeout(() => {
            getlog(page,'',20);     
       
        }, 300);
    }
    //   }
 
    }
};

const mobileset=(v:any)=>{
    console.log(v.length);
    
if(v.length<=10){
    setmobile(v);
}else{
    setmobile(mobile)
}

}
  return (
    <div style={{overflow:spinner=='paginationload' ? 'hidden':'auto',height:'600px'}} onScroll={(e)=>handleScroll(e)}>

{spinner=='paginationload' || loading? <div style={{
        textAlign: 'center',
        position: 'absolute',
        zIndex: '1000',
        left: '47%',
        top: '50%',
}}>
<img height={100} width={100} src="https://i.gifer.com/ZZ5H.gif"/>
</div>:''}
  <Dialog
        
        title="Help"
        icon="info-sign"
        isOpen={tooltipopen}
        style={{zIndex:'2000'}}
        onClose={()=>settooltipopen(false)}>
        <div style={{padding:'10px',zIndex:'20000'}}>
        {tooltiptext}
        </div>
        </Dialog>

        <Dialog
        
        title="Trasaction Details"
        icon="info-sign"
        isOpen={model}
        style={{width:'90%'}}                
        onClose={()=>setmodel(false)}>
        <div className={Classes.DIALOG_BODY} style={{overflow:'auto'}}>

            {rows!==null &&rows[0]!==undefined && rows.length>0?<HTMLTable border={1}>
            <tr>
                    <th>Detail Number <Icon style={{cursor:'pointer'}} icon='info-sign'  onClick={()=>tooltip({tooltipopen:true,tooltiptext:'An internal reference number in Crush for further transaction details.'})}/></th>
                    <th>Transaction ID <Icon style={{cursor:'pointer'}} icon='info-sign'  onClick={()=>tooltip({tooltipopen:true,tooltiptext:'This is the reference number in Crush for this specific transaction.'})}/></th>
                    <th>Discount Rate (%) <Icon style={{cursor:'pointer'}} icon='info-sign'  onClick={()=>tooltip({tooltipopen:true,tooltiptext:'The discount rate applied from the coupon. Calculated as a percentage of the Part Total.'})}/></th>
                    <th>Discount Type <Icon style={{cursor:'pointer'}} icon='info-sign'  onClick={()=>tooltip({tooltipopen:true,tooltiptext:'The specific type of coupon applied in this transaction.'})}/></th>
                    <th>Part Total <Icon style={{cursor:'pointer'}} icon='info-sign'  onClick={()=>tooltip({tooltipopen:true,tooltiptext:'The total price of all parts in the transaction before applying any discounts.'})}/></th>
                    <th>Discount Amount <Icon style={{cursor:'pointer'}} icon='info-sign'  onClick={()=>tooltip({tooltipopen:true,tooltiptext:'Total dollar amount saved based on the Part Total and Discount Rate.'})}/></th>
                    <th>Pricing Standard <Icon style={{cursor:'pointer'}} icon='info-sign'  onClick={()=>tooltip({tooltipopen:true,tooltiptext:'Pricing standard used for this order, typically set as'})}/></th>
                    <th>Part Name <Icon style={{cursor:'pointer'}} icon='info-sign'  onClick={()=>tooltip({tooltipopen:true,tooltiptext:'The specific type of part(s) associated with this transaction.'})}/></th>
                    <th>Part Quantity <Icon style={{cursor:'pointer'}} icon='info-sign'  onClick={()=>tooltip({tooltipopen:true,tooltiptext:'The total quantity of parts included in this transaction.'})}/></th>
                    <th>Core Quantity <Icon style={{cursor:'pointer'}} icon='info-sign'  onClick={()=>tooltip({tooltipopen:true,tooltiptext:'The number of cores (recyclable parts) included in this order.'})}/></th>
                    <th>Core Total <Icon style={{cursor:'pointer'}} icon='info-sign'  onClick={()=>tooltip({tooltipopen:true,tooltiptext:'The core charge amount for this transaction, representing the cost of recyclable parts.'})}/></th>
                    <th>Warranty Total <Icon style={{cursor:'pointer'}} icon='info-sign'  onClick={()=>tooltip({tooltipopen:true,tooltiptext:'The amount spent on warranties for this order, which may be $0 if no warranty was purchased.'})}/></th>
                   
                   
                </tr>
             { rows.map((x:any)=>(  <tr>
                
                <td>{x.DetailNo}</td>
                <td>{x.TransRno}</td>
                <td>{x.DiscountRate* 100}%</td>
                <td>{x.DiscountName==null ? 'N/A' :x.DiscountName}</td>
                <td>{parseInt(x.PartTotal)<0?'-' :''}${formatAmountx(Number(x.PartTotal).toFixed(2))} </td>
                <td>${Number(x.DiscountAmount).toFixed(2)}</td> 
                <td>{x.PriceList}</td>
                <td>{x.PartName}</td>
                <td>{x.PartQty}</td>                        
                <td>{x.CoreQty}</td>                        
                <td>${Number(x.CoreTotal).toFixed(2)}</td>
                <td>${Number(x.WarrantyTotal).toFixed(2)}</td>
                                
                </tr>))}
              
 

            </HTMLTable>:''}
  </div>
  </Dialog>

<ButtonGroup style={{margin:'5px 5px 5px 0px',float:'right'}}>
<InputGroup value={mobile} max={10} maxLength={10} type="number" onChange={(e)=>mobileset(e.target.value)}/>&nbsp;
<Button disabled={mobile==''} intent="success" onClick={()=>{mobile!=='' ? getlog(1,mobile,20):alert('Please enter mobile number.')}}>Search</Button>&nbsp;
<Button   onClick={()=>{setmobile(''); getlog(1,'',20)}}>Clear filter</Button>&nbsp;
<Button disabled={loading} intent="success"  onClick={csvDownloads}>Csv Downloads</Button>
</ButtonGroup>

    <HTMLTable>
      <thead>
        <tr>
          <th style={styleTh2}>Date</th>
          <th style={styleTh2}>Mobile</th>
          <th style={styleTh2}>Amount</th>
          <th style={styleTh2}>Transaction ID</th>
          <th style={styleTh2}>Details</th>
        </tr>
      </thead>
      <tbody>
        {loading ? (
          <tr>
            <td colSpan={5} style={{ color: "#757575", textAlign: "center" }}>
              <em>Loading...</em>
            </td>
          </tr>
        ) : dataRows.length > 0 ? (
          dataRows.map((x) => (
            <tr key={x.transaction_id} style={{background:parseInt(x.amount_spent)>0?'#caffca' :'#ff8d8d'}}>
              <td style={styleTh}>{formatDate(x.created_date)} </td>
              <td style={styleTh}>{x.phone_number}</td>
              <td style={styleTh}>{parseInt(x.amount_spent)<0?'-' :''}${formatAmountx(x.amount_spent)}</td>
              <td style={styleTh}>{x.transaction_id}</td>
              <td style={styleTh}><Icon onClick={()=>OpenPopup(x)} icon="eye-open"/></td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan={5} style={{ color: "#757575", textAlign: "center" }}>
              <em>No Transaction History Found.</em>
            </td>
          </tr>
        )}
      </tbody>
    </HTMLTable> 
    
    


    <HTMLTable id="myTable" style={{display:'none'}}>
      <thead>
        <tr>
          <th style={styleTh2}>Date</th>
          <th style={styleTh2}>Mobile</th>
          <th style={styleTh2}>Amount</th>
          <th style={styleTh2}>Transaction ID</th>
          <th style={styleTh2}>Details</th>
        </tr>
      </thead>
      <tbody>
        {loading ? (
          <tr>
            <td colSpan={5} style={{ color: "#757575", textAlign: "center" }}>
              <em>Loading...</em>
            </td>
          </tr>
        ) : dataRows.length > 0 ? (
          dataRows.map((x) => (
            <div>
              <tr>
                <td colSpan={5}></td>
              </tr>
            <tr key={x.transaction_id} >
              <td  style={styleTh}>{formatDate(x.created_date)} </td>
              <td style={styleTh}>{x.phone_number}</td>
              <td style={styleTh}>{parseInt(x.amount_spent)<0?'-' :''}${formatAmountx(x.amount_spent)}</td>
              <td style={styleTh}>{x.transaction_id}</td>
              <td style={styleTh}><Icon onClick={()=>OpenPopup(x)} icon="eye-open"/></td>
            </tr>
 


          { x.transaction_details!==null &&x.transaction_details[0]!==undefined && x.transaction_details.length>0?  <tr>
                      <th>Detail Number <Icon style={{cursor:'pointer'}} icon='info-sign'  onClick={()=>tooltip({tooltipopen:true,tooltiptext:'An internal reference number in Crush for further transaction details.'})}/></th>
                      <th>Transaction ID <Icon style={{cursor:'pointer'}} icon='info-sign'  onClick={()=>tooltip({tooltipopen:true,tooltiptext:'This is the reference number in Crush for this specific transaction.'})}/></th>
                      <th>Discount Rate (%) <Icon style={{cursor:'pointer'}} icon='info-sign'  onClick={()=>tooltip({tooltipopen:true,tooltiptext:'The discount rate applied from the coupon. Calculated as a percentage of the Part Total.'})}/></th>
                      <th>Discount Type <Icon style={{cursor:'pointer'}} icon='info-sign'  onClick={()=>tooltip({tooltipopen:true,tooltiptext:'The specific type of coupon applied in this transaction.'})}/></th>
                      <th>Part Total <Icon style={{cursor:'pointer'}} icon='info-sign'  onClick={()=>tooltip({tooltipopen:true,tooltiptext:'The total price of all parts in the transaction before applying any discounts.'})}/></th>
                      <th>Discount Amount <Icon style={{cursor:'pointer'}} icon='info-sign'  onClick={()=>tooltip({tooltipopen:true,tooltiptext:'Total dollar amount saved based on the Part Total and Discount Rate.'})}/></th>
                      <th>Pricing Standard <Icon style={{cursor:'pointer'}} icon='info-sign'  onClick={()=>tooltip({tooltipopen:true,tooltiptext:'Pricing standard used for this order, typically set as'})}/></th>
                      <th>Part Name <Icon style={{cursor:'pointer'}} icon='info-sign'  onClick={()=>tooltip({tooltipopen:true,tooltiptext:'The specific type of part(s) associated with this transaction.'})}/></th>
                      <th>Part Quantity <Icon style={{cursor:'pointer'}} icon='info-sign'  onClick={()=>tooltip({tooltipopen:true,tooltiptext:'The total quantity of parts included in this transaction.'})}/></th>
                      <th>Core Quantity <Icon style={{cursor:'pointer'}} icon='info-sign'  onClick={()=>tooltip({tooltipopen:true,tooltiptext:'The number of cores (recyclable parts) included in this order.'})}/></th>
                      <th>Core Total <Icon style={{cursor:'pointer'}} icon='info-sign'  onClick={()=>tooltip({tooltipopen:true,tooltiptext:'The core charge amount for this transaction, representing the cost of recyclable parts.'})}/></th>
                      <th>Warranty Total <Icon style={{cursor:'pointer'}} icon='info-sign'  onClick={()=>tooltip({tooltipopen:true,tooltiptext:'The amount spent on warranties for this order, which may be $0 if no warranty was purchased.'})}/></th>
                     
                     
                  </tr>:''}
       {x.transaction_details!==null &&x.transaction_details[0]!==undefined && x.transaction_details.length>0?
            
               JSON.parse(x.transaction_details).map((xx:any)=>(  <tr >
                  
                  <td>{xx.DetailNo}</td>
                  <td>{xx.TransRno}</td>
                  <td>{xx.DiscountRate* 100}%</td>
                  <td>{xx.DiscountName==null ? 'N/A' :xx.DiscountName}</td>
                  <td>{parseInt(xx.PartTotal)<0?'-' :''}${formatAmountx(Number(xx.PartTotal).toFixed(2))}  </td>
                  <td>${Number(xx.DiscountAmount).toFixed(2)}</td> 
                  <td>{xx.PriceList}</td>
                  <td>{xx.PartName}</td>
                  <td>{xx.PartQty}</td>                        
                  <td>{xx.CoreQty}</td>                        
                  <td>${Number(xx.CoreTotal).toFixed(2)}</td>
                  <td>${Number(xx.WarrantyTotal).toFixed(2)}</td>
                                  
                  </tr>))
                
   
  :''}
          
          
            </div>
          ))
        ) : (
          <tr>
            <td colSpan={5} style={{ color: "#757575", textAlign: "center" }}>
              <em>No Transaction History Found.</em>
            </td>
          </tr>
        )}
      </tbody>
    </HTMLTable> 


    


       </div>
  );
};

export default LocationTransactionsLogData;
